'use strict';

var Base = require('front/components/Base/Base');

require('./IndexRoute.less');



let pointsProgress = [
    { progress: 0.0, isVisible: false },
    { progress: 0.01787139418411118, isVisible: false },
    { progress: 0.04289514416928428, isVisible: false },
    { progress: 0.07544071125207442, isVisible: false },
    { progress: 0.07952821579449496, isVisible: false },
    { progress: 0.08543187981604411, isVisible: false },
    { progress: 0.09971961209952565, isVisible: false },
    { progress: 0.11812045901159625, isVisible: false },
    { progress: 0.12425474859858912, isVisible: false },
    { progress: 0.16671114944873616, isVisible: false },
    { progress: 0.1889959681131513, isVisible: false },
    { progress: 0.20595968482138716, isVisible: false },
    { progress: 0.2278387858049223, isVisible: false },
    { progress: 0.26122449850396656, isVisible: false },
    { progress: 0.3263971805755045, isVisible: false },
    { progress: 0.3661320380055027, isVisible: false },
    { progress: 0.39143561187954845, isVisible: false },
    { progress: 0.3988204824111276, isVisible: false },
    { progress: 0.4271283889725935, isVisible: false },
    { progress: 0.4428385519550594, isVisible: false },
    { progress: 0.4969542889110214, isVisible: false },
    { progress: 0.5496497327890786, isVisible: false },
    { progress: 0.5535831724447066, isVisible: false },
    { progress: 0.5691443999603987, isVisible: false },
    { progress: 0.6012954369262666, isVisible: false },
    { progress: 0.612345245578486, isVisible: false },
    { progress: 0.6515327211141142, isVisible: false },
    { progress: 0.667788688588494, isVisible: false },
    { progress: 0.696773207910632, isVisible: false },
    { progress: 0.717434212322037, isVisible: false },
    { progress: 0.7418624604088286, isVisible: false },
    { progress: 0.8148942039758447, isVisible: false },
    { progress: 0.8849507294797163, isVisible: false },
    { progress: 0.8879200167860636, isVisible: false },
    { progress: 0.9391536681157931, isVisible: false },
    { progress: 0.9986554683700609, isVisible: false }
];

module.exports = Base.extend({

    events: {
    },

    initialize: function (options) {
        this.options = options || {};


        app.vent.on('loaded', () => {
            this.headerAnimator = new app.utils.HeaderAnimator(this.$('h2'));
        });

        this.map = this.$('.IndexRoute-map')[0];
        this.routeMask1 = this.$('#route_mask_1')[0];
        this.routeMask2 = this.$('#route_mask_2')[0];

        const routeLength1 = this.routeMask1.getTotalLength();
        const routeLength2 = this.routeMask2.getTotalLength();

        this.routeMask1.style.strokeDasharray = `${routeLength1} ${routeLength1}`;
        this.routeMask2.style.strokeDasharray = `${routeLength2} ${routeLength2}`;

        this.$('h2').onCustom('screen-enter', (e, data) => {
            this.headerAnimator.fire(app.settings.showDelay);
            this.headerAnimator = null;
            data.$el.offCustom('screen-enter');
        });

        // $(window).on('keyup', (e) => {
        //     if (e.keyCode == 13) {
        //         console.log(this.progress);
        //     }
        // });

        pointsProgress.forEach((item, index) => {
            item.pointEl = this.$('#point_' + index)[0];
            item.textEl = this.$('#text_' + index)[0];
        });

        let firstScroll = true;

        this.$el.onCustom('screen-position', (e, data) => {
            if (firstScroll) {
                firstScroll = false;

                return;
            }
            const start = (app.sizes.windowHeight - (app.sizes.isPhoneLayout ? 287 : 420) * app.settings.scale) ;
            const end = -270 * app.settings.scale;
            let progress = (data.screenTop - start) / (end - start);
            progress = Math.min(Math.max(progress, 0), 1);

            progress = app.utils.easingFunctions(0, 1, progress, 'easeInOutQuad');

            // this.progress = progress;

            pointsProgress.forEach((item) => {
                if (item.progress <= progress && !item.isVisible) {
                    item.pointEl.style.opacity = 1;
                    item.textEl.style.opacity = 1;
                    item.isVisible = true;
                }

                if (item.progress > progress && item.isVisible) {
                    item.pointEl.style.opacity = 0;
                    item.textEl.style.opacity = 0;
                    item.isVisible = false;
                }
            });

            const wrapperSize = (!app.sizes.isPhoneLayout ? 720 : 320) * app.settings.scale;
            const mapSize = wrapperSize * (!app.sizes.isPhoneLayout ? 2 : 4.25);
            this.map.style.transform = `translateX(${-(mapSize - wrapperSize) * progress}px)`;
            // this.map.style.left = -(mapSize - wrapperSize) * progress + 'px';

            const dist = progress * (routeLength1 + routeLength2);
            this.routeMask1.style.strokeDashoffset = -Math.min(dist, routeLength1);
            this.routeMask2.style.strokeDashoffset = -Math.max(Math.min(dist - routeLength1, routeLength2), 0);
        });

        this.$('.IndexRoute-number').onCustom('screen-enter', (e, data) => {
            const $item = data.$el.find('.IndexRoute-number-value');
            const $number = $item.closest('.IndexRoute-number');

            if (!$number.hasClass('fade')) {
                app.utils.animateNumber(
                    $item,
                    $item.attr('data-start'),
                    $item.attr('data-end'),
                    app.settings.showDelay,
                    1050,
                    null,
                    'easeOutQuart',
                    ' '
                );
            } else {
                $number.addClass('show');
            }

            data.$el.offCustom('screen-enter');
        });


        Base.prototype.initialize.call(this, options);
    }

});
