'use strict';

var Base = require('front/components/Base/Base');

require('./IndexProcess.less');

module.exports = Base.extend({

    events: {
        'click .IndexProcess-stage-text .anchor': 'scrollToFeedback'
    },

    initialize: function (options) {
        this.options = options || {};


        app.vent.on('loaded', () => {
            this.headerAnimator = new app.utils.HeaderAnimator(this.$('h2'));
        });

        this.$('h2').onCustom('screen-enter', (e, data) => {
            this.headerAnimator.fire(app.settings.showDelay);
            this.headerAnimator = null;
            data.$el.offCustom('screen-enter');
        });

        this.$('.IndexProcess-stage').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });


        this.$('.IndexProcess-collage').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });


        Base.prototype.initialize.call(this, options);
    },


    scrollToFeedback: function () {
        app.vent.trigger('scroll-to-feedback');
    }
});
