'use strict';

var _ = require('underscore/underscore');
var Base = require('front/components/Base/Base');

require('./CommonGallery.less');

module.exports = Base.extend({

    events: {
        'click .CommonGallery-wrapper .CommonGallery-item': 'onImgClick',
        'mousedown .CommonGallery-wrapper .CommonGallery-item': 'onImgMouseDown',
        'click .CommonGallery-dots div': 'onDotClick'
    },

    initialize: function (options) {
        this.options = options || {};

        _.bindAll(this, 'onResize', 'onKeyUp');

        this.size = this.$el.hasClass('small') ? 'small' : 'big';
        this.index = options.startIndex || 0;

        this.$items = this.$('.CommonGallery-item');
        this.$dots = this.$('.CommonGallery-dots div');
        this.count = this.$items.length;

        this.$dynamicCss = null;
        app.settings.galleryId = (app.settings.galleryId || 0) + 1;
        this.id = app.settings.galleryId;

        this.$el.attr('data-id', this.id);

        this.onResize();

        app.vent.on('resize', this.onResize);

        if (this.size === 'big') {
            $(window).on('keyup', this.onKeyUp);
        }

        this.$wrapper = this.$('.CommonGallery-wrapper');

        // навешиваем наш самописный плагин для вытягивания-утягивания элементов
        this.picsSwipe = this.$wrapper.Swiper({

            $scrollElements: this.$el, //это чтоюы можно было скролить страницу поверх слайдшоу

            maxTransitionSpeed: this.getPicsTransitionTime(),

            // сила пружинки при натяжении первой картинки вправо и наоборот.
            // чем больше значение дроби, тем жесче "пружина", 1/1 нет пружины, 1/<1 обратная "пружина".
            rubberBandForce: 1 / 1.47,

            // функция опрашивается в самом начале свайпа,
            // чтобы знать в каком направлении ожидается жест и его ограничения по сдвигу.
            getCurrentConstraints: function (/*$target*/) {
                var res = {};
                var size = this.dims.iw + this.dims.margin;

                // указываем элементы, которые надо двигать
                const $curImage = this.$items.eq(this.index);

                res.$moveItems = $curImage.add($curImage.prev()).add($curImage.next())
                    .add($curImage.prev().prev())
                    .add($curImage.next().next());

                // в качестве референсного объекта берем либо предыдущую, либо следующую картинку,
                // но не центральную, поскольку у нее транзишен меньше остальных.
                // т.е. по ней нельзя ни ожидать транзишенов, ни смотреть, есть сейчас анимация или нету.
                // res.$referenceItem = res.$moveItems.filter(':not(.center-image)').first();
                res.$referenceItem = $curImage;

                // запрещаем сдвиг всего влево если мы на последней картинке
                if (this.index === this.count - 1) {
                    // noTrigger:true означает что в этом направлении триггерить жест не надо,
                    // но сам факт присутствия объекта у данного направления 'up' говорит о том,
                    // что в данном направлении будет работать пружинка и движение
                    //в этом направлении разрешено для инициации жеста.
                    res.left = {max: 0, noTrigger: true};
                } else {
                    res.left = {max: size};
                }

                // запрещаем сдвиг всего вправо если мы на первой картинке
                if (this.index === 0) {
                    res.right = {max: 0, noTrigger: true};
                } else {
                    res.right = {max: size};
                }

                return res;
            }.bind(this),

            onSwipeStart: function () {
                this.$wrapper.removeClass('has-animation');
            }.bind(this),

            customMoveCheck: function (/*$obj, shift*/) {
                // если сейчас сдвиг отрицательный значит страницы двужутся влево,
                // значит левую страницу двигать не надо, ее все равно не видно,
                // ну и с правой страницей такой же алгоритм.
                // if ($obj.attr('data-pos') === "-1" && shift < 0) {
                //     return false;
                // }
                // if ($obj.attr('data-pos') === "1" && shift > 0) {
                //     return false;
                // }

                return true;
            }.bind(this),


            callback: function (dir) {
                this.$wrapper.addClass('has-animation');

                //не прошли должного расстояния или в запрещенную сторону тянули
                if (!dir) {
                    return;
                }

                // в конце анимации мы делаем стандартный goTo без анимации,
                // чтобы все работало нормально (goTo много чего делает).
                // делать это надо именно в конце анимации иначе у нас будет рваная анимация прокрутки к нужной картинке
                let index = this.index;

                if (dir === 'right') {
                    index = this.index - 1;
                }

                if (dir === 'left') {
                    index = this.index + 1;
                }


                this.goTo(index, true);
            }.bind(this)

        })
            .data('swipe');

        this.goTo(this.index, true);

        Base.prototype.initialize.call(this, options);
    },


    // подбираем скорость анимации картинок.
    getPicsTransitionTime: function () {
        return app.sizes.isPhoneLayout ? 300 : 450;
    },

    loadLazy: function (index) {
        const lazyLoad = (index) => {
            let $item = this.$items.eq(index);

            if (!$item.length) {
                return;
            }

            $item = $item.find('img');

            if ($item.data('loaded')) {
                return;
            }

            $item.data('loaded', true);
            $item.parent()
                .find('source, img')
                .each(function () {
                    var srcset = this.getAttribute('data-srcset');
                    var sizes = this.getAttribute('data-sizes');
                    var src = this.getAttribute('data-src');
                    srcset && this.setAttribute('srcset', srcset);
                    sizes && this.setAttribute('sizes', sizes);
                    src && this.setAttribute('src', src);
                });
        };

        lazyLoad(index);

        if (index < this.count - 1) {
            lazyLoad(index + 1);
        }

        if (index < this.count - 2) {
            lazyLoad(index + 2);
        }

        if (index > 0) {
            lazyLoad(index - 1);
        }

        if (index > 1) {
            lazyLoad(index - 2);
        }
    },

    onResize: function () {
        if (!this.$dynamicCss) {
            this.$dynamicCss = $('<style type="text/css">');
            this.$dynamicCss.appendTo($('body'));
        }

        const aspect = this.$items[0].getAttribute('data-aspect') - 0;

        //wrapper dims
        const ww = this.$('.CommonGallery-wrapper').width();
        const wh = this.$('.CommonGallery-wrapper').height();

        const margins = {
            'small': [10, 20],
            'big': [10, 40]
        };

        const minOverflows = {
            'small': [10, 40],
            'big': [10, 140]
        };

        const layoutIndex = app.sizes.isPhoneLayout ? 0 : 1;
        const margin = Math.round(margins[this.size][layoutIndex] * app.settings.scale);
        const minOverflow = Math.round(minOverflows[this.size][layoutIndex] * app.settings.scale);

        //bounding container for one image dims
        const cw = ww - (margin + minOverflow) * 2; //overflow + margin for prev and next images
        const ch = wh;

        //image size
        const iw = Math.round(cw / ch > aspect ? ch * aspect : cw);
        const ih = Math.round(cw / ch > aspect ? ch : cw / aspect);
        const it = Math.round((wh - ih) / 2);
        const il = Math.round((ww - iw) / 2);

        const shift = iw + margin;

        this.dims = {
            iw,
            ih,
            it,
            il,
            shift,
            margin
        };

        const style = `
            .CommonGallery[data-id="${this.id}"] .CommonGallery-item {
                width: ${iw}px;
                height: ${ih}px;
                left: ${il}px;
                top: ${it}px;
                z-index: 1;
            }
            .CommonGallery[data-id="${this.id}"] .CommonGallery-item[data-pos="-2"] {
                transform: translateX(${-shift * 2}px);
                z-index: 1;
            }
            .CommonGallery[data-id="${this.id}"] .CommonGallery-item[data-pos="-1"] {
                transform: translateX(${-shift}px);
                z-index: 2;
            }
            .CommonGallery[data-id="${this.id}"] .CommonGallery-item[data-pos="0"] {
                transform: translateX(0);
                z-index: 3;
            }
            .CommonGallery[data-id="${this.id}"] .CommonGallery-item[data-pos="1"] {
                transform: translateX(${shift}px);
                z-index: 2;
            }
            .CommonGallery[data-id="${this.id}"] .CommonGallery-item[data-pos="2"] {
                transform: translateX(${shift * 2}px);
                z-index: 1;
            }
        `;

        this.$dynamicCss.text(style);
    },


    onKeyUp: function (e) {
        if (e.keyCode === 37) { //left
            this.goTo('prev');
        }

        if (e.keyCode === 39) { //right
            this.goTo('next');
        }
    },


    onDotClick: function (e) {
        const $dot = $(e.currentTarget);
        this.goTo($dot.index());
    },


    goTo: function (dir, noAnimation) {
        if (noAnimation) {
            this.$wrapper.unFlashClass('has-animation', 17);
        } else {
            this.$wrapper.addClass('has-animation');
        }

        if (dir === 'next') {
            this.index++;
        } else if (dir === 'prev') {
            this.index--;
        } else {
            this.index = dir;
        }

        if (this.index < 0) {
            this.index = 0;
        }

        if (this.index >= this.count) {
            this.index = this.count - 1;
        }

        this.$dots.removeClass('active').eq(this.index)
            .addClass('active');

        this.$items.each((index, item) => {
            if (index <= this.index - 2) {
                item.setAttribute('data-pos', -2);
            } else
            if (index === this.index - 1) {
                item.setAttribute('data-pos', -1);
            } else
            if (index === this.index) {
                item.setAttribute('data-pos', 0);
            } else
            if (index === this.index + 1) {
                item.setAttribute('data-pos', 1);
            } else
            if (index >= this.index + 2) {
                item.setAttribute('data-pos', 2);
            }
        });

        this.trigger('index-changed', this.index);

        this.loadLazy(this.index);
    },


    onImgMouseDown: function (e) {
        this.prevImgMouseX = e.clientX;
    },


    onImgClick: function (e) {
        //prevent click on drag
        if (Math.abs(e.clientX - this.prevImgMouseX) > 5) {
            return;
        }

        const $item = $(e.currentTarget).closest('.CommonGallery-item');

        if ($item.attr('data-pos') === '-1') {
            this.goTo('prev');

            return;
        }

        if ($item.attr('data-pos') === '0') {
            if (this.size === 'small') {
                Backbone.history.navigate(window.Urls['front:gallery-popup'](this.index + 1), {trigger: 'true'});
            } else {
                // app.vent.trigger('close-gallery', this.slider.getInfo().displayIndex);
            }

            return;
        }

        if ($item.attr('data-pos') === '1') {
            this.goTo('next');

            return;
        }
    },


    destroy: function () {
        app.vent.off('resize', this.onResize);

        this.$dynamicCss && this.$dynamicCss.remove();

        $(window).off('keyup', this.onKeyUp);

        this.picsSwipe && this.picsSwipe.destroy();

        return Base.prototype.destroy.call(this);
    }
});
