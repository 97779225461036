var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/django/sencessence.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/utils/macros.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["id"], 
["bound", "title"], 
function (l_id, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("id", l_id);
frame.set("bound", Object.prototype.hasOwnProperty.call(kwargs, "bound") ? kwargs["bound"] : "");frame.set("title", Object.prototype.hasOwnProperty.call(kwargs, "title") ? kwargs["title"] : "");var t_2 = "";t_2 += "<svg";
if(runtime.contextOrFrameLookup(context, frame, "bound")) {
t_2 += " viewBox=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "bound"), env.opts.autoescape);
t_2 += "\"";
;
}
t_2 += ">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "title")) {
t_2 += "<title>";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_2 += "</title>";
;
}
t_2 += "\n        <use xlink:href=\"#";
t_2 += runtime.suppressValue(l_id, env.opts.autoescape);
t_2 += "\"></use>\n    </svg>";
t_2 += runtime.suppressValue((lineno = 4, colno = 19, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("svgSprite");
context.setVariable("svgSprite", macro_t_1);
output += "\n\n";
var macro_t_3 = runtime.makeMacro(
["component"], 
["mods", "classes", "data"], 
function (l_component, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("component", l_component);
frame.set("mods", Object.prototype.hasOwnProperty.call(kwargs, "mods") ? kwargs["mods"] : []);frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_4 = "";if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"item")) {
var t_5;
t_5 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"item");
frame.set("item", t_5, true);
if(frame.topLevel) {
context.setVariable("item", t_5);
}
if(frame.topLevel) {
context.addExport("item", t_5);
}
;
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate(l_component, false, "assets/app/front/utils/macros.jinja", false, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
callback(null,t_6);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
callback(null,t_8);});
});
tasks.push(
function(result, callback){
t_4 += result;
callback(null);
});
env.waterfall(tasks, function(){
t_4 += runtime.suppressValue((lineno = 11, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
});
frame = callerFrame;
return new runtime.SafeString(t_4);
});
context.addExport("includeComponent");
context.setVariable("includeComponent", macro_t_3);
output += "\n\n\n";
var macro_t_10 = runtime.makeMacro(
["data", "alt"], 
["color", "isReal", "isLazy", "sizes", "min", "max"], 
function (l_data, l_alt, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", l_data);
frame.set("alt", l_alt);
frame.set("color", Object.prototype.hasOwnProperty.call(kwargs, "color") ? kwargs["color"] : "transparent");frame.set("isReal", Object.prototype.hasOwnProperty.call(kwargs, "isReal") ? kwargs["isReal"] : false);frame.set("isLazy", Object.prototype.hasOwnProperty.call(kwargs, "isLazy") ? kwargs["isLazy"] : true);frame.set("sizes", Object.prototype.hasOwnProperty.call(kwargs, "sizes") ? kwargs["sizes"] : "");frame.set("min", Object.prototype.hasOwnProperty.call(kwargs, "min") ? kwargs["min"] : 0);frame.set("max", Object.prototype.hasOwnProperty.call(kwargs, "max") ? kwargs["max"] : 9999);var t_11 = "";t_11 += "\n ";
var t_12;
t_12 = "";
frame.set("srcsetWebp3x", t_12, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_12);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_12);
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w120webp")) {
t_11 += "\n        ";
var t_13;
t_13 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w120webp") + " 180w, ";
frame.set("srcsetWebp3x", t_13, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_13);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_13);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w240webp")) {
t_11 += "\n        ";
var t_14;
t_14 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w240webp") + " 360w, ";
frame.set("srcsetWebp3x", t_14, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_14);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_14);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w400webp")) {
t_11 += "\n        ";
var t_15;
t_15 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w400webp") + " 600w, ";
frame.set("srcsetWebp3x", t_15, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_15);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_15);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w600webp")) {
t_11 += "\n        ";
var t_16;
t_16 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w600webp") + " 900w, ";
frame.set("srcsetWebp3x", t_16, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_16);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_16);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w800webp")) {
t_11 += "\n        ";
var t_17;
t_17 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w800webp") + " 1200w, ";
frame.set("srcsetWebp3x", t_17, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_17);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_17);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1024webp")) {
t_11 += "\n        ";
var t_18;
t_18 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w1024webp") + " 1536w, ";
frame.set("srcsetWebp3x", t_18, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_18);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_18);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1280webp")) {
t_11 += "\n        ";
var t_19;
t_19 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w1280webp") + " 1920w, ";
frame.set("srcsetWebp3x", t_19, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_19);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_19);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1440webp")) {
t_11 += "\n        ";
var t_20;
t_20 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w1440webp") + " 2160w, ";
frame.set("srcsetWebp3x", t_20, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_20);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_20);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1680webp")) {
t_11 += "\n        ";
var t_21;
t_21 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w1680webp") + " 2520w, ";
frame.set("srcsetWebp3x", t_21, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_21);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_21);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1920webp")) {
t_11 += "\n        ";
var t_22;
t_22 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w1920webp") + " 2880w, ";
frame.set("srcsetWebp3x", t_22, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_22);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_22);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2160webp")) {
t_11 += "\n        ";
var t_23;
t_23 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w2160webp") + " 3240w, ";
frame.set("srcsetWebp3x", t_23, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_23);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_23);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2560webp")) {
t_11 += "\n        ";
var t_24;
t_24 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x") + runtime.memberLookup((l_data),"w2560webp") + " 3840w";
frame.set("srcsetWebp3x", t_24, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp3x", t_24);
}
if(frame.topLevel) {
context.addExport("srcsetWebp3x", t_24);
}
t_11 += "\n    ";
;
}
t_11 += "\n\n    ";
var t_25;
t_25 = "";
frame.set("srcset3x", t_25, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_25);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_25);
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w120")) {
t_11 += "\n        ";
var t_26;
t_26 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w120") + " 180w, ";
frame.set("srcset3x", t_26, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_26);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_26);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w240")) {
t_11 += "\n        ";
var t_27;
t_27 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w240") + " 360w, ";
frame.set("srcset3x", t_27, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_27);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_27);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w400")) {
t_11 += "\n        ";
var t_28;
t_28 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w400") + " 600w, ";
frame.set("srcset3x", t_28, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_28);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_28);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w600")) {
t_11 += "\n        ";
var t_29;
t_29 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w600") + " 900w, ";
frame.set("srcset3x", t_29, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_29);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_29);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w800")) {
t_11 += "\n        ";
var t_30;
t_30 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w800") + " 1200w, ";
frame.set("srcset3x", t_30, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_30);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_30);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1024")) {
t_11 += "\n        ";
var t_31;
t_31 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w1024") + " 1536w, ";
frame.set("srcset3x", t_31, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_31);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_31);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1280")) {
t_11 += "\n        ";
var t_32;
t_32 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w1280") + " 1920w, ";
frame.set("srcset3x", t_32, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_32);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_32);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1440")) {
t_11 += "\n        ";
var t_33;
t_33 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w1440") + " 2160w, ";
frame.set("srcset3x", t_33, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_33);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_33);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1680")) {
t_11 += "\n        ";
var t_34;
t_34 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w1680") + " 2520w, ";
frame.set("srcset3x", t_34, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_34);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_34);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1920")) {
t_11 += "\n        ";
var t_35;
t_35 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w1920") + " 2880w, ";
frame.set("srcset3x", t_35, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_35);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_35);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2160")) {
t_11 += "\n        ";
var t_36;
t_36 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w2160") + " 3240w, ";
frame.set("srcset3x", t_36, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_36);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_36);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2560")) {
t_11 += "\n        ";
var t_37;
t_37 = runtime.contextOrFrameLookup(context, frame, "srcset3x") + runtime.memberLookup((l_data),"w2560") + " 3840w";
frame.set("srcset3x", t_37, true);
if(frame.topLevel) {
context.setVariable("srcset3x", t_37);
}
if(frame.topLevel) {
context.addExport("srcset3x", t_37);
}
t_11 += "\n    ";
;
}
t_11 += "\n\n    ";
var t_38;
t_38 = "";
frame.set("srcsetWebp2x", t_38, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_38);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_38);
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w120webp")) {
t_11 += "\n        ";
var t_39;
t_39 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w120webp") + " 160w, ";
frame.set("srcsetWebp2x", t_39, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_39);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_39);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w240webp")) {
t_11 += "\n        ";
var t_40;
t_40 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w240webp") + " 320w, ";
frame.set("srcsetWebp2x", t_40, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_40);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_40);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w400webp")) {
t_11 += "\n        ";
var t_41;
t_41 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w400webp") + " 534w, ";
frame.set("srcsetWebp2x", t_41, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_41);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_41);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w600webp")) {
t_11 += "\n        ";
var t_42;
t_42 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w600webp") + " 800w, ";
frame.set("srcsetWebp2x", t_42, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_42);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_42);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w800webp")) {
t_11 += "\n        ";
var t_43;
t_43 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w800webp") + " 1067w, ";
frame.set("srcsetWebp2x", t_43, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_43);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_43);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1024webp")) {
t_11 += "\n        ";
var t_44;
t_44 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w1024webp") + " 1366w, ";
frame.set("srcsetWebp2x", t_44, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_44);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_44);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1280webp")) {
t_11 += "\n        ";
var t_45;
t_45 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w1280webp") + " 1707w, ";
frame.set("srcsetWebp2x", t_45, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_45);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_45);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1440webp")) {
t_11 += "\n        ";
var t_46;
t_46 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w1440webp") + " 1920w, ";
frame.set("srcsetWebp2x", t_46, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_46);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_46);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1680webp")) {
t_11 += "\n        ";
var t_47;
t_47 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w1680webp") + " 2240w, ";
frame.set("srcsetWebp2x", t_47, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_47);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_47);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1920webp")) {
t_11 += "\n        ";
var t_48;
t_48 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w1920webp") + " 2560w, ";
frame.set("srcsetWebp2x", t_48, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_48);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_48);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2160webp")) {
t_11 += "\n        ";
var t_49;
t_49 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w2160webp") + " 2880w, ";
frame.set("srcsetWebp2x", t_49, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_49);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_49);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2560webp")) {
t_11 += "\n        ";
var t_50;
t_50 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x") + runtime.memberLookup((l_data),"w2560webp") + " 3414w";
frame.set("srcsetWebp2x", t_50, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp2x", t_50);
}
if(frame.topLevel) {
context.addExport("srcsetWebp2x", t_50);
}
t_11 += "\n    ";
;
}
t_11 += "\n\n    ";
var t_51;
t_51 = "";
frame.set("srcset2x", t_51, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_51);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_51);
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w120")) {
t_11 += "\n        ";
var t_52;
t_52 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w120") + " 160w, ";
frame.set("srcset2x", t_52, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_52);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_52);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w240")) {
t_11 += "\n        ";
var t_53;
t_53 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w240") + " 320w, ";
frame.set("srcset2x", t_53, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_53);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_53);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w400")) {
t_11 += "\n        ";
var t_54;
t_54 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w400") + " 534w, ";
frame.set("srcset2x", t_54, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_54);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_54);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w600")) {
t_11 += "\n        ";
var t_55;
t_55 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w600") + " 800w, ";
frame.set("srcset2x", t_55, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_55);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_55);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w800")) {
t_11 += "\n        ";
var t_56;
t_56 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w800") + " 1067w, ";
frame.set("srcset2x", t_56, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_56);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_56);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1024")) {
t_11 += "\n        ";
var t_57;
t_57 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w1024") + " 1366w, ";
frame.set("srcset2x", t_57, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_57);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_57);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1280")) {
t_11 += "\n        ";
var t_58;
t_58 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w1280") + " 1707w, ";
frame.set("srcset2x", t_58, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_58);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_58);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1440")) {
t_11 += "\n        ";
var t_59;
t_59 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w1440") + " 1920w, ";
frame.set("srcset2x", t_59, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_59);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_59);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1680")) {
t_11 += "\n        ";
var t_60;
t_60 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w1680") + " 2240w, ";
frame.set("srcset2x", t_60, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_60);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_60);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1920")) {
t_11 += "\n        ";
var t_61;
t_61 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w1920") + " 2560w, ";
frame.set("srcset2x", t_61, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_61);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_61);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2160")) {
t_11 += "\n        ";
var t_62;
t_62 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w2160") + " 2880w, ";
frame.set("srcset2x", t_62, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_62);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_62);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2560")) {
t_11 += "\n        ";
var t_63;
t_63 = runtime.contextOrFrameLookup(context, frame, "srcset2x") + runtime.memberLookup((l_data),"w2560") + " 3414w";
frame.set("srcset2x", t_63, true);
if(frame.topLevel) {
context.setVariable("srcset2x", t_63);
}
if(frame.topLevel) {
context.addExport("srcset2x", t_63);
}
t_11 += "\n    ";
;
}
t_11 += "\n\n    ";
var t_64;
t_64 = "";
frame.set("srcsetWebp1x", t_64, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_64);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_64);
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w120webp")) {
t_11 += "\n        ";
var t_65;
t_65 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w120webp") + " 120w, ";
frame.set("srcsetWebp1x", t_65, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_65);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_65);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w240webp")) {
t_11 += "\n        ";
var t_66;
t_66 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w240webp") + " 240w, ";
frame.set("srcsetWebp1x", t_66, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_66);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_66);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w400webp")) {
t_11 += "\n        ";
var t_67;
t_67 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w400webp") + " 400w, ";
frame.set("srcsetWebp1x", t_67, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_67);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_67);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w600webp")) {
t_11 += "\n        ";
var t_68;
t_68 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w600webp") + " 600w, ";
frame.set("srcsetWebp1x", t_68, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_68);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_68);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w800webp")) {
t_11 += "\n        ";
var t_69;
t_69 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w800webp") + " 800w, ";
frame.set("srcsetWebp1x", t_69, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_69);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_69);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1024webp")) {
t_11 += "\n        ";
var t_70;
t_70 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w1024webp") + " 1024w, ";
frame.set("srcsetWebp1x", t_70, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_70);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_70);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1280webp")) {
t_11 += "\n        ";
var t_71;
t_71 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w1280webp") + " 1280w, ";
frame.set("srcsetWebp1x", t_71, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_71);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_71);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1440webp")) {
t_11 += "\n        ";
var t_72;
t_72 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w1440webp") + " 1440w, ";
frame.set("srcsetWebp1x", t_72, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_72);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_72);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1680webp")) {
t_11 += "\n        ";
var t_73;
t_73 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w1680webp") + " 1680w, ";
frame.set("srcsetWebp1x", t_73, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_73);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_73);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1920webp")) {
t_11 += "\n        ";
var t_74;
t_74 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w1920webp") + " 1920w, ";
frame.set("srcsetWebp1x", t_74, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_74);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_74);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2160webp")) {
t_11 += "\n        ";
var t_75;
t_75 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w2160webp") + " 2160w, ";
frame.set("srcsetWebp1x", t_75, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_75);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_75);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2560webp")) {
t_11 += "\n        ";
var t_76;
t_76 = runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x") + runtime.memberLookup((l_data),"w2560webp") + " 2560w";
frame.set("srcsetWebp1x", t_76, true);
if(frame.topLevel) {
context.setVariable("srcsetWebp1x", t_76);
}
if(frame.topLevel) {
context.addExport("srcsetWebp1x", t_76);
}
t_11 += "\n    ";
;
}
t_11 += "\n\n    ";
var t_77;
t_77 = "";
frame.set("srcset1x", t_77, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_77);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_77);
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w120")) {
t_11 += "\n        ";
var t_78;
t_78 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w120") + " 120w, ";
frame.set("srcset1x", t_78, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_78);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_78);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w240")) {
t_11 += "\n        ";
var t_79;
t_79 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w240") + " 240w, ";
frame.set("srcset1x", t_79, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_79);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_79);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w400")) {
t_11 += "\n        ";
var t_80;
t_80 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w400") + " 400w, ";
frame.set("srcset1x", t_80, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_80);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_80);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w600")) {
t_11 += "\n        ";
var t_81;
t_81 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w600") + " 600w, ";
frame.set("srcset1x", t_81, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_81);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_81);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w800")) {
t_11 += "\n        ";
var t_82;
t_82 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w800") + " 800w, ";
frame.set("srcset1x", t_82, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_82);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_82);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1024")) {
t_11 += "\n        ";
var t_83;
t_83 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w1024") + " 1024w, ";
frame.set("srcset1x", t_83, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_83);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_83);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1280")) {
t_11 += "\n        ";
var t_84;
t_84 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w1280") + " 1280w, ";
frame.set("srcset1x", t_84, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_84);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_84);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1440")) {
t_11 += "\n        ";
var t_85;
t_85 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w1440") + " 1440w, ";
frame.set("srcset1x", t_85, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_85);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_85);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1680")) {
t_11 += "\n        ";
var t_86;
t_86 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w1680") + " 1680w, ";
frame.set("srcset1x", t_86, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_86);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_86);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w1920")) {
t_11 += "\n        ";
var t_87;
t_87 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w1920") + " 1920w, ";
frame.set("srcset1x", t_87, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_87);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_87);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2160")) {
t_11 += "\n        ";
var t_88;
t_88 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w2160") + " 2160w, ";
frame.set("srcset1x", t_88, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_88);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_88);
}
t_11 += "\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_data),"w2560")) {
t_11 += "\n        ";
var t_89;
t_89 = runtime.contextOrFrameLookup(context, frame, "srcset1x") + runtime.memberLookup((l_data),"w2560") + " 2560w";
frame.set("srcset1x", t_89, true);
if(frame.topLevel) {
context.setVariable("srcset1x", t_89);
}
if(frame.topLevel) {
context.addExport("srcset1x", t_89);
}
t_11 += "\n    ";
;
}
t_11 += "\n\n\n\n    ";
if((runtime.contextOrFrameLookup(context, frame, "isReal"))) {
t_11 += "\n        <picture >\n            <source srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x"), env.opts.autoescape);
t_11 += "\" media=\"(min-resolution: 3dppx)\" sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" type=\"image/webp\">\n            <source srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcset3x"), env.opts.autoescape);
t_11 += "\" media=\"(min-resolution: 3dppx)\" sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" >\n            <source srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x"), env.opts.autoescape);
t_11 += "\" media=\"(min-resolution: 2dppx)\" sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" type=\"image/webp\">\n            <source srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcset2x"), env.opts.autoescape);
t_11 += "\" media=\"(min-resolution: 2dppx)\" sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" >\n            <source srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x"), env.opts.autoescape);
t_11 += "\"  sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" type=\"image/webp\">\n            <source srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcset1x"), env.opts.autoescape);
t_11 += "\"  sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" >\n            <img src=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((l_data),"w400"), env.opts.autoescape);
t_11 += "\" alt=\"";
t_11 += runtime.suppressValue(l_alt, env.opts.autoescape);
t_11 += "\" style=\"background-color: ";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "color"), env.opts.autoescape);
t_11 += "\">\n        </picture>\n    ";
;
}
else {
t_11 += "\n        <picture >\n            <source sizes=\"1px\" srcset=\"";
t_11 += runtime.suppressValue((lineno = 258, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/stub.png"])), env.opts.autoescape);
t_11 += " 1w\" data-srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcsetWebp3x"), env.opts.autoescape);
t_11 += "\" media=\"(min-resolution: 3dppx)\" data-sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" type=\"image/webp\">\n            <source sizes=\"1px\" srcset=\"";
t_11 += runtime.suppressValue((lineno = 259, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/stub.png"])), env.opts.autoescape);
t_11 += " 1w\" data-srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcset3x"), env.opts.autoescape);
t_11 += "\" media=\"(min-resolution: 3dppx)\" data-sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" >\n            <source sizes=\"1px\" srcset=\"";
t_11 += runtime.suppressValue((lineno = 260, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/stub.png"])), env.opts.autoescape);
t_11 += " 1w\" data-srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcsetWebp2x"), env.opts.autoescape);
t_11 += "\" media=\"(min-resolution: 2dppx)\" data-sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" type=\"image/webp\">\n            <source sizes=\"1px\" srcset=\"";
t_11 += runtime.suppressValue((lineno = 261, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/stub.png"])), env.opts.autoescape);
t_11 += " 1w\" data-srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcset2x"), env.opts.autoescape);
t_11 += "\" media=\"(min-resolution: 2dppx)\" data-sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" >\n            <source sizes=\"1px\" srcset=\"";
t_11 += runtime.suppressValue((lineno = 262, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/stub.png"])), env.opts.autoescape);
t_11 += " 1w\" data-srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcsetWebp1x"), env.opts.autoescape);
t_11 += "\"  data-sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" type=\"image/webp\">\n            <source sizes=\"1px\" srcset=\"";
t_11 += runtime.suppressValue((lineno = 263, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/stub.png"])), env.opts.autoescape);
t_11 += " 1w\" data-srcset=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcset1x"), env.opts.autoescape);
t_11 += "\"  data-sizes=\"";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
t_11 += "\" >\n            <img ";
if(runtime.contextOrFrameLookup(context, frame, "isLazy")) {
t_11 += "data-lazy";
;
}
t_11 += " src=\"";
t_11 += runtime.suppressValue((lineno = 264, colno = 67, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/stub.png"])), env.opts.autoescape);
t_11 += "\" data-src=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((l_data),"w400"), env.opts.autoescape);
t_11 += "\" alt=\"";
t_11 += runtime.suppressValue(l_alt, env.opts.autoescape);
t_11 += "\" style=\"background-color: ";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "color"), env.opts.autoescape);
t_11 += "\">\n        </picture>\n    ";
;
}
t_11 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_11);
});
context.addExport("picture");
context.setVariable("picture", macro_t_10);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/utils/macros.jinja"] , dependencies)