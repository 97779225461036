'use strict';

var Base = require('front/components/Base/Base');

require('./IndexLoader.less');

module.exports = Base.extend({

    events: {
    },

    initialize: function (options) {
        this.options = options || {};

        if (app.sizes.isPhoneLayout) {
            this.$el.hide();

            setTimeout(() => {
                app.vent.trigger('loaded');
            }, 17);
        } else {
            this.$el.addClass('show');

            setTimeout(() => {
                this.$el.hide();
                app.vent.trigger('loaded');
            }, 700);
        }

        Base.prototype.initialize.call(this, options);
    }

});
