'use strict';

var Base = require('front/components/Base/Base');

require('./IndexHero.less');

module.exports = Base.extend({

    events: {
    },

    initialize: function (options) {
        this.options = options || {};

        app.vent.on('loaded', () => {
            this.$('p').addClass('show');
        });

        let reachTop = true;

        this.$el.onCustom('screen-position', (e, data) => {
            let progress = 1 - (data.screenTop + data.height) / data.height;
            progress = Math.max(Math.min(progress, 1), 0);

            if (progress > 0.1 && reachTop) {
                reachTop = false;
                app.vent.trigger('reach-top', false);
            }

            if (progress <= 0.1 && !reachTop) {
                reachTop = true;
                app.vent.trigger('reach-top', true);
            }
        });

        Base.prototype.initialize.call(this, options);
    }

});
