'use strict';

var Base = require('front/components/Base/Base');

require('./IndexInside.less');

var CommonGallery = require('front/components/CommonGallery/CommonGallery');

module.exports = Base.extend({

    events: {
    },

    initialize: function (options) {
        this.options = options || {};



        app.vent.on('loaded', () => {
            this.headerAnimator = new app.utils.HeaderAnimator(this.$('h2'));
        });

        this.$('h2').onCustom('screen-enter', (e, data) => {
            this.headerAnimator.fire(app.settings.showDelay);
            this.headerAnimator = null;
            data.$el.offCustom('screen-enter');
        });


        this.$('.IndexInside-text').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');

            this.commonGallery = new CommonGallery({
                el: this.$('.IndexInside-gallery .CommonGallery')[0]
            });
        });


        this.$('.IndexInside-gallery').onCustom('screen-enter', (e, data) => {
            setTimeout(() => {
                this.commonGallery.goTo('next');
            }, app.settings.showDelay);
            data.$el.offCustom('screen-enter');
        });

        this.$('.IndexInside-feature').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });

        Base.prototype.initialize.call(this, options);
    }
});
