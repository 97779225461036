'use strict';

var _ = require('underscore/underscore');
var BasePopup = require('front/components/BasePopup/BasePopup');

var template = require('./SuccessPopup.jinja');
require('./SuccessPopup.less');

module.exports = BasePopup.extend({

    template: template,

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER:  _.extend({

    }, BasePopup.prototype.VIEWS_TO_REGISTER),

    events: _.extend({
    }, BasePopup.prototype.baseEvents),


    apiUrl: function () {
        return window.UrlsAPI['api:success-popup']('json');
    },

    renderData: function () {
        return this.data || {};
    },

    initialize: function (options) {
        this.options = options || {};

        this.showLoader = true;

        this.orderId = localStorage.getItem('orderId');
        this.email = localStorage.getItem('email');

        if (!this.orderId || this.orderId !== options.orderId) {
            window.location.href = '/';
        } else {
            this.updateEmail();
        }

        BasePopup.prototype.initialize.call(this, options);
    },


    activate: function () {
        return BasePopup.prototype.activate.call(this).then(() => {
            // this.updateEmail();
        });
    },


    deactivate: function (params) {
        return BasePopup.prototype.deactivate.call(this, params);
    },


    updateEmail: function () {
        const $text = this.$('.SuccessPopup-text');
        let html = $text.html();
        html = html.replace('%email%', this.email);
        $text.html(html);
        localStorage.setItem('orderId', '');
        localStorage.setItem('email', '');
        this.$('.SuccessPopup').css('display', 'block');
    },


    onResize: function () {
        if (app.settings.isMobile) {
            this.$('.SuccessPopup').css('height', window.innerHeight);
            this.$('.SuccessPopup').css('min-height', window.innerHeight);
            this.$('.BasePopup-content').css('height', window.innerHeight);
            this.$('.BasePopup-content').css('min-height', window.innerHeight);
        }

        BasePopup.prototype.onResize.call(this);
    }


});
