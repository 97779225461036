'use strict';

var Base = require('front/components/Base/Base');

require('./IndexHelp.less');

module.exports = Base.extend({

    events: {
    },

    initialize: function (options) {
        this.options = options || {};


        app.vent.on('loaded', () => {
            this.headerAnimator = new app.utils.HeaderAnimator(this.$('h2'));
        });

        this.$('h2').onCustom('screen-enter', (e, data) => {
            this.headerAnimator.fire(app.settings.showDelay);
            this.headerAnimator = null;
            data.$el.offCustom('screen-enter');
        });

        this.$('.IndexHelp-text').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });

        this.$('.IndexHelp-fund').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });

        this.$('.IndexHelp-result').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });

        this.$('.IndexHelp-progress').onCustom('screen-enter', (e, data) => {
            const $item = data.$el.find('.IndexHelp-progress-raised span');
            const $progressBar = data.$el.find('.IndexHelp-progress-bar span');
            const max = $progressBar.attr('data-total') - 0;
            const raised = Math.min($progressBar.attr('data-raised') - 0, max);

            app.utils.animateNumber(
                $item,
                $item.attr('data-start'),
                raised,
                app.settings.showDelay,
                1050,
                null,
                'easeOutQuart',
                ' '
            );


            $progressBar.css({
                'width': 100 * raised / max + '%'
            });

            data.$el.offCustom('screen-enter');
        });

        Base.prototype.initialize.call(this, options);
    }

});
