'use strict';

var _ = require('underscore');
var Base = require('front/components/Base/Base');

require('./IndexCover.less');

const PHOTOS_SETTINGS = {
    speed: {
        'small': 0.1,
        'medium': 0.2
    },
    xRange: {
        'small': 14, //17 columns 40px each
        'medium': 13
    },
    sizes: {
        'small': [120, 80],
        'medium': [160, 107]
    },
    minYDist: 50,
    maxYDist: 300,
    density: 1 / 50
};


module.exports = Base.extend({

    events: {
        'click .IndexCover-photos-photo': 'onPhotoClick'
    },

    initialize: function (options) {
        this.options = options || {};

        _.bindAll(this, 'onResize');

        this.photos = [];
        this.deleted = [];

        this.currentDy = 0;
        this.scrollShifts = [];
        this.scrollShiftsIndex = 0;
        this.scrollShiftsLen = 1;
        this.targetDy = 0;


        const headerTop = this.$('h1 span')[0];
        const headerBottom = this.$('h1 span')[1];


        this.$cover = this.$('.IndexCover-image');
        this.$note = this.$('.IndexCover-note');
        this.photosContainer = this.$('.IndexCover-photos')[0];

        app.vent.on('scroll', () => {
            if (app.state.popupShown) {
                return;
            }

            const coverShift = Math.min(app.sizes.scrollTop, app.sizes.windowHeight);
            if (coverShift !== this.prevCoverShift) {
                this.$cover[0].style.transform = 'translateY(' + -coverShift + 'px)';
                this.prevCoverShift = coverShift;
            }

            const scrollShift = app.sizes.scrollTop - this.prevScrollTop;
            this.scrollShifts[this.scrollShiftsIndex] = scrollShift;
            this.scrollShiftsIndex = (this.scrollShiftsIndex + 1) % this.scrollShiftsLen;
            this.targetDy = this.scrollShifts.reduce((a, b) => a + b, 0) / this.scrollShiftsLen;
            this.prevScrollTop = app.sizes.scrollTop;
        });

        app.vent.on('loaded', () => {
            this.headerTopAnimator = new app.utils.HeaderAnimator($(headerTop));
            this.headerBottomAnimator = new app.utils.HeaderAnimator($(headerBottom));

            setTimeout(() => {
                this.headerTopAnimator.fire();
                this.headerBottomAnimator.fire();

                this.headerTopAnimator = null;
                this.headerBottomAnimator = null;
            }, app.sizes.isPhoneLayout ? 300 : 0);


            this.$el.addClass('show');
            this.$cover.addClass('show');
            this.$note.addClass('show');

            setTimeout(() => {
                this.$cover.addClass('shown');
                this.$note.addClass('shown');
                this.$('.IndexCover-image-buy').addClass('shown');
            }, 1000);
        });


        app.vent.on('footer-progress', (data) => {
            if (app.sizes.isPhoneLayout) {
                return;
            }

            const progress = app.utils.easingFunctions(0, 1, data.progress, 'easeInOutQuad');
            const shiftTop = data.height / 2 + (app.sizes.windowHeight - data.height)  - 119 * app.settings.scale; //119 is magic number to place words at the same baseline as footer words, have not time to do math calculations in general
            const shiftBottom = data.height / 2 - 140 * app.settings.scale; //140 is magic number to place words at the same baseline as footer words, have not time to do math calculations in general
            headerTop.style.transform = 'translateY(' + progress * shiftTop + 'px)';
            headerBottom.style.transform = 'translateY(' + -progress * shiftBottom + 'px)';
        });

        this.position = {
            top: true,
            bottom: false
        };

        app.vent.on('reach-top', (state) => {
            this.position.top = state;
            this.updateState();
        });

        app.vent.on('reach-bottom', (state) => {
            this.position.bottom = state;
            this.updateState();
        });

        app.vent.on('resize', this.onResize);

        Base.prototype.initialize.call(this, options);
    },


    updateState: function () {
        if (app.sizes.isPhoneLayout) {
            return;
        }

        let pos = 'middle';

        if (this.position.top) {
            pos = 'top';
        }
        if (this.position.bottom) {
            pos = 'bottom';
        }

        // this.$cover.toggleClass('show', pos === 'top');
        // this.$note.toggleClass('show', pos !== 'bottom');

        this.prevScrollTop = app.sizes.scrollTop;
        this.targetDy = 0;

        if (pos === 'middle') {
            this.startPhotos();
        } else {
            this.stopPhotos();
        }
    },


    addRandomPhoto: function (y, index) {
        if (this.lastPhoto && Math.abs(y) - this.lastPhoto.y < PHOTOS_SETTINGS.minYDist && Math.random() < 0.5) {
            return;
        }

        const size = Math.random() < 0.5 ? 'small' : 'medium';
        let el;
        let img;

        if (!this.deleted.length) {
            el = document.createElement('div');
            img = document.createElement('img');
            el.className = 'IndexCover-photos-photo ' + size;
            el.appendChild(img);
            this.photosContainer.appendChild(el);
        } else {
            const deleted = this.deleted.pop();
            el = deleted.el;
            img = deleted.img;
            el.className = 'IndexCover-photos-photo ' + size;
            el.style.opacity = '';
        }

        const xRange = 17;
        const speed = PHOTOS_SETTINGS.speed[size];
        let x = Math.round(Math.random() * PHOTOS_SETTINGS.xRange[size]);

        if (Math.random() < 0.5 && this.lastPhoto) {
            x = (this.lastPhoto.x + Math.round(xRange / 2)) % PHOTOS_SETTINGS.xRange[size];
        }

        let item = {
            size: size,
            y: y,
            x: x,
            el: el,
            img: img,
            index: index,
            speed: speed + Math.random() * speed / 10
        };
        const imageRealIndex = app.data.photosRandomMap[this.normalizeIndex(index)];
        item.el.style.transform = 'translateY(' + item.y * app.settings.scale + 'px)';
        item.el.style.left = 100 * x / xRange + '%';
        item.el.style.top = '0px';
        item.el.setAttribute('data-index', imageRealIndex);
        img.setAttribute('src', app.data.photos[imageRealIndex].image[app.settings.isWebp ? 'w400webp' : 'w400']);

        this.photos.push(item);

        this.lastPhoto = item;
    },


    startPhotos: function () {
        clearTimeout(this.stopTimeout);

        cancelAnimationFrame(this.photosRAF);
        this.currentDy = 0;

        this.$('.IndexCover-photos').addClass('show');

        const animate = () => {
            this.photosRAF = requestAnimationFrame(animate);

            this.targetDy *= 0.5; //to fade out last scroll event

            let hasDeleted = false;
            let targetDy = app.state.popupShown ? 0 : this.targetDy;
            let minIndex = 99999;
            let maxIndex = -1;

            this.currentDy = this.currentDy + (targetDy - this.currentDy) * 0.08;


            const yDistrib = [];

            this.photos.forEach(item => {
                item.y -= item.speed + this.currentDy;

                minIndex = Math.min(item.index, minIndex);
                maxIndex = Math.max(item.index, maxIndex);

                if (item.y < -300 || item.y > app.sizes.windowHeight / app.settings.scale) {
                    item.deleted = true;
                    item.el.style.opacity = 0;
                    hasDeleted = true;
                } else {
                    item.el.style.transform = 'translateY(' + item.y * app.settings.scale + 'px)';
                    const blockIndex = Math.round(item.y *  PHOTOS_SETTINGS.density);
                    yDistrib[blockIndex] = (yDistrib[blockIndex] || 0) + 1;
                }
            });

            if (minIndex === 99999) {
                minIndex = 1;
            }

            //mult to size, cause size is pixels step, for 0.5 we should reduce twice
            //the  probability of new photos addition (per frame)
            if (this.currentDy >= 0) {//scroll down
                if (Math.random() < PHOTOS_SETTINGS.density * (PHOTOS_SETTINGS.speed.small + this.currentDy)) {
                    if (app.sizes.scrollTop < app.sizes.totalPageHeight - app.sizes.windowHeight * 2.5
                        || app.state.popupShown) {
                        const y = app.sizes.windowHeight / app.settings.scale;
                        //prevent extra creation above exisingn on scroll up|down at same position
                        if (!(yDistrib[Math.round(y * PHOTOS_SETTINGS.density)] > 0)) {
                            this.addRandomPhoto(y, maxIndex + 1);
                        }
                    }
                }
            } else {//scroll up
                if (Math.random() < PHOTOS_SETTINGS.density * (PHOTOS_SETTINGS.speed.small - this.currentDy)) {
                    if (app.sizes.scrollTop > app.sizes.windowHeight) {
                        const y = (-150) / app.settings.scale;
                        //prevent extra creation above exisingn on scroll up|down at same position
                        if (!(yDistrib[Math.round(y * PHOTOS_SETTINGS.density)] > 0)) {
                            this.addRandomPhoto(y, minIndex - 1);
                        }
                    }
                }
            }

            if (hasDeleted) {
                this.deleted = this.deleted.concat(this.photos.filter(item => item.deleted));
                this.photos = this.photos.filter(item => !item.deleted);
            }
        };

        animate();
    },


    normalizeIndex: function (index) {
        return (index + app.data.photos.length * 1000) % app.data.photos.length;
    },


    onPhotoClick: function (e) {
        let index = this.normalizeIndex(e.currentTarget.getAttribute('data-index') - 0);
        Backbone.history.navigate(window.Urls['front:photos-popup'](index + 1), {trigger: 'true'});
    },


    stopPhotos: function () {
        clearTimeout(this.stopTimeout);

        this.stopTimeout = setTimeout(() => {
            cancelAnimationFrame(this.photosRAF);
            this.currentDy = 0;
            this.photos = [];
            this.deleted = [];
            this.$('.IndexCover-photos').empty();
        }, 500);

        this.$('.IndexCover-photos').removeClass('show');
    },


    onResize: function () {
        const w = this.$('.IndexCover-image').width();
        const h = this.$('.IndexCover-image').height();
        const aspect = 2139 / 2953;
        let iw;
        let ih;

        if (w / h > aspect) {
            iw = h * aspect;
            ih = h;
        } else {
            iw = w;
            ih = w / aspect;
        }

        const dx = Math.round((w - iw) / 2);
        const dy = Math.round((h - ih) / 2);

        this.$('.IndexCover-image img').css({
            left: dx,
            top: dy,
            width: Math.round(iw),
            height: Math.round(ih)
        });
    }
});
