var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/BasePopup/BasePopup.jinja"] = require( "front/components/BasePopup/BasePopup.jinja" );




var shim = require("/var/www/django/sencessence.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/PhotosPopup/PhotosPopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/PhotosPopup/PhotosPopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n";
env.getTemplate("front/components/BasePopup/BasePopup.jinja", true, "assets/app/front/pages/PhotosPopup/PhotosPopup.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
parentTemplate = t_5
for(var t_4 in parentTemplate.blocks) {
context.addBlock(t_4, parentTemplate.blocks[t_4]);
}
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popupMetaTitle"))(env, context, frame, runtime, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
output += t_7;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popupContent"))(env, context, frame, runtime, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
output += t_9;
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popupMetaTitle(env, context, frame, runtime, cb) {
var lineno = 3;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"OG")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popupContent(env, context, frame, runtime, cb) {
var lineno = 5;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"PhotosPopup\">\n        <div class=\"PhotosPopup-images\">\n            ";
frame = frame.push();
var t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"photos");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("photo", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\n                <div class=\"PhotosPopup-image\">\n                    <div class=\"PhotosPopup-image-wrapper\" data-aspect=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_14),"image")),"aspect"), env.opts.autoescape);
output += "\">\n                        ";
output += runtime.suppressValue((lineno = 11, colno = 41, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"picture"), "macros[\"picture\"]", context, [runtime.makeKeywordArgs({"data": runtime.memberLookup((t_14),"image"),"alt": runtime.memberLookup((t_14),"title"),"color": "transparent","isReal": false,"isLazy": false,"sizes": "(max-width: 1023px) 159.37vw, (max-width: 1919px) 100vw, 1920px","min": 510,"max": 2293.40625})])), env.opts.autoescape);
output += "\n                    </div>\n\n                    <div class=\"PhotosPopup-image-info\">\n                        <div class=\"PhotosPopup-image-info-top\">\n                            <div class=\"PhotosPopup-image-info-title\">";
output += runtime.suppressValue(runtime.memberLookup((t_14),"title"), env.opts.autoescape);
output += "</div>\n                            <div class=\"PhotosPopup-image-info-place\">";
output += runtime.suppressValue(runtime.memberLookup((t_14),"place"), env.opts.autoescape);
output += "</div>\n                        </div>\n                        <div class=\"PhotosPopup-image-info-numbers\">\n                            ";
frame = frame.push();
var t_17 = runtime.memberLookup((t_14),"numbers");
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("number", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
output += "\n                                <div class=\"PhotosPopup-image-info-number\">\n                                    <div class=\"PhotosPopup-image-info-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_18),"label"), env.opts.autoescape);
output += "</div>\n                                    <div class=\"PhotosPopup-image-info-value\">";
output += runtime.suppressValue(runtime.memberLookup((t_18),"value"), env.opts.autoescape);
output += "</div>\n                                </div>\n                            ";
;
}
}
frame = frame.pop();
output += "\n                        </div>\n                    </div>\n\n                </div>\n            ";
;
}
}
frame = frame.pop();
output += "\n        </div>\n        <button class=\"PhotosPopup-arrow-prev\">";
output += runtime.suppressValue((lineno = 41, colno = 71, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["order-arrow","0 0 56 33",runtime.makeKeywordArgs({"caller": (function (){var macro_t_19 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_20 = "";;
frame = frame.pop();
return new runtime.SafeString(t_20);
});
return macro_t_19;})()})])), env.opts.autoescape);
output += "</button>\n        <button class=\"PhotosPopup-arrow-next\">";
output += runtime.suppressValue((lineno = 42, colno = 71, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["order-arrow","0 0 56 33",runtime.makeKeywordArgs({"caller": (function (){var macro_t_21 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_22 = "";;
frame = frame.pop();
return new runtime.SafeString(t_22);
});
return macro_t_21;})()})])), env.opts.autoescape);
output += "</button>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_popupMetaTitle: b_popupMetaTitle,
b_popupContent: b_popupContent,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/PhotosPopup/PhotosPopup.jinja"] , dependencies)