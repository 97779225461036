var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/sencessence.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/CommonGallery/CommonGallery.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/CommonGallery/CommonGallery.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data", "size"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("size", Object.prototype.hasOwnProperty.call(kwargs, "size") ? kwargs["size"] : "small");var t_5 = "";t_5 += "\n    <div class=\"CommonGallery ";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "size"), env.opts.autoescape);
t_5 += "\">\n        <div class=\"CommonGallery-wrapper\">\n            ";
frame = frame.push();
var t_8 = runtime.contextOrFrameLookup(context, frame, "data");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("image", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n                <div class=\"CommonGallery-item\" data-aspect=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"aspect"), env.opts.autoescape);
t_5 += "\">\n                    ";
if(runtime.contextOrFrameLookup(context, frame, "size") == "small") {
t_5 += "\n                        ";
t_5 += runtime.suppressValue((lineno = 8, colno = 41, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"picture"), "macros[\"picture\"]", context, [runtime.makeKeywordArgs({"data": t_9,"alt": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"color": "transparent","isReal": false,"isLazy": (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") < 2?true:false),"sizes": "(max-width: 1023px) 87.5vw, (max-width: 1919px) 41.66vw, 800px","min": 280,"max": 1259.125})])), env.opts.autoescape);
t_5 += "\n                    ";
;
}
else {
t_5 += "\n                        ";
t_5 += runtime.suppressValue((lineno = 20, colno = 41, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"picture"), "macros[\"picture\"]", context, [runtime.makeKeywordArgs({"data": t_9,"alt": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"color": "transparent","isReal": false,"isLazy": false,"sizes": "(max-width: 1023px) 212.5vw, (max-width: 1919px) 75vw, 1440px","min": 680,"max": 3057.875})])), env.opts.autoescape);
t_5 += "\n                    ";
;
}
t_5 += "\n                </div>\n            ";
;
}
}
frame = frame.pop();
t_5 += "\n        </div>\n\n        <div class=\"CommonGallery-dots\">\n            ";
frame = frame.push();
var t_12 = runtime.contextOrFrameLookup(context, frame, "data");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("image", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_5 += "\n                <div></div>\n            ";
;
}
}
frame = frame.pop();
t_5 += "\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("CommonGallery");
context.setVariable("CommonGallery", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/CommonGallery/CommonGallery.jinja"] , dependencies)