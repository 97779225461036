'use strict';

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

var Backbone = require('backbone');
var _ = require('underscore');
var FontFaceObserver = require('fontfaceobserver');

//Backend urls chhemas to generate them on frontend
window.Urls = require('django_js_front/reverse.js');
window.UrlsAPI = require('django_js_front_api/reverse.js');

var Settings = require('./Settings');
var Router = require('./Router');
var Utils = require('front/utils/Utils.js');

require('front/components/Content/Content');


require('front/style.less');
// require('mq-genie');

//fix for hovers|active on mobile, what a weird behavior!
//fastclick solves this problem, but adds a bunch of other
if (!app.settings.isDesktop) {
    document.addEventListener('touchstart', function () {}, {passive: true});
}


app.configure = Settings.configure;
app.configure();

window.app.vent = _.extend({}, Backbone.Events);

var AeroportRegular = new FontFaceObserver('AeroportRegular');
var AeroportMedium = new FontFaceObserver('AeroportMedium');
var KazimirRegular = new FontFaceObserver('KazimirRegular');


Promise.all([AeroportRegular.load(), AeroportMedium.load(), KazimirRegular.load()]).then(function () {
    // console.log('Fonts loaded');
    window.app.state.fontsLoaded = true;
    window.app.vent.trigger('fonts-loaded');
}, function () {
    console.log('Fonts is not available');
});


window.app.utils = Utils;

window.app.els = {
    $window: $(window),
    $body: $('body'),
    $htmlBody: $('html,body'),
    $content: $('.Content')
};

window.app.isPhoneLayout = function () {
    return window.matchMedia('(max-width: 1023px)').matches;
};


app.router = new Router();

app.els.$body.on('click', '.u-Route', function (e) {
    // var currentRoute = Backbone.history.getFragment();

    var parentUrl = $(e.target)
        .closest('a')
        .attr('href');

    var url = $(e.currentTarget).attr('href') || parentUrl;

    e.preventDefault();
    Backbone.history.navigate(url, {trigger: 'true'});
});


app.views = {

};


app.sizes = {
    scrollTop: window.pageYOffset,
    isPhoneLayout: window.app.isPhoneLayout()
};


var isTinkoffConnected = false;
window.app.connectTinkoff = function () {
    if (isTinkoffConnected) {
        return;
    }

    $('body').append('<script src="https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js"></script>');

    isTinkoffConnected = true;
};


//proceed any popup show/hide
//set all underlying content fixed position
app.vent.on('PopupShown', function () {
    var $items = [];
    var tops = [];
    var scrollTop = $(window).scrollTop();

    //first of all get top position of all elements that need to be fixed on popup shown
    $('.NeedFixOnPopup').each(function (id, item) {
        var $item = $(item);
        $items.push($item);
        tops.push($item.offset().top);
    });

    //after that set all items fixed position and position them at the very point there where they was
    _.each($items, function ($item, ind) {
        $item
            .css('top', tops[ind] - scrollTop)
            .addClass('FixedOnPopup');
    });

    app.state.popupShown = true;

    app.state.forceResize = true;
    $(window).resize();
}.bind(this));


app.vent.on('PopupHidded', function () {
    $('.NeedFixOnPopup').each(function (id, item) {
        $(item)
            .css('top', '')
            .removeClass('FixedOnPopup');
    });

    app.state.popupShown = false;

    app.state.forceResize = true;
    $(window).resize();
}.bind(this));


const onScroll = () => {
    window.app.vent.trigger('scroll');
};

const onScrollThrottled = _.throttle(() => {
    window.app.vent.trigger('scroll-throttled');
}, 100, {trailing: true, leading: true});

const onResize = () => {
    window.app.vent.trigger('resize');
};

const onResizeThrottled = _.throttle(() => {
    window.app.vent.trigger('resize-throttled');
}, 100, {trailing: true, leading: true});

const onResizeDebounced = _.debounce(() => {
    window.app.vent.trigger('resize-debounced');
}, 500);



var startDate = +new Date();

$(window)
    .on('scroll', function () {
        window.app.sizes.scrollTop = window.pageYOffset;
        onScroll();
        onScrollThrottled();
    })
    .on('resize', function () {
        if (new Date() - startDate > 1000 && !app.state.forceResize) {
            $('html').flashClass('no-transition', 100);
        }
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
        const windowInnerHeight = window.innerHeight;
        const isPhoneLayout = window.app.isPhoneLayout();
        const totalPageHeight = document.body.scrollHeight;

        window.app.sizes.scrollTop = window.pageYOffset;
        window.app.sizes.totalPageHeight = totalPageHeight;

        window.app.sizes.windowInnerHeight = windowInnerHeight;

        //usefull for mobile scroll, when bottom nav panel hides/reveals on up/down scroll
        //causing resize event, which is very harmhull for us
        if (windowWidth === window.app.sizes.windowWidth &&
            windowHeight === window.app.sizes.windowHeight &&
            !app.state.forceResize) {
            return;
        }

        /* eslint-disable */
        //logical xor, means if both is not equal
        if (isPhoneLayout != window.app.sizes.isPhoneLayout) {
            app.vent.trigger('layout-switch', isPhoneLayout);
        }
        /* eslint-enable */

        app.state.forceResize = false;
        window.app.sizes.windowWidth = windowWidth;
        window.app.sizes.windowHeight = windowHeight;
        window.app.sizes.isPhoneLayout = isPhoneLayout;

        onResize();
        onResizeThrottled();
        onResizeDebounced();
    });



app.utils.detectVideoAutoplayFeature(function (res) {
    app.settings.hasAutoPlay = res;

    app.router.start();
});




/* eslint-disable */
const generateMacros = (fixedSizes) => {

    let sizes = fixedSizes;

    const generateSrcset = (varName, tp, scale) => {
        let res = `{% set ${varName} = '' %}`;
        let keysCount = Object.keys(sizes).length;

        // if (keysCount == 1 && scale > 1) {
        //     return '';
        // }

        for (let size in sizes) {
            if (sizes.hasOwnProperty(size)) {
                let isLast = !--keysCount;
                res += `
    {% set ${varName} = ${varName} + data.w${sizes[size]}${tp ? tp : ''} + ' ${Math.ceil(sizes[size] * scale)}w${isLast ? '' : ', '}' %}`;
            }
        }

        return res;
    };


    const generateSource = (srcset, media, tp, isReal) => {
        let keysCount = Object.keys(sizes).length;

        if (keysCount === 1 && media) {
            return '';
        }

        if (isReal) {
            return `<source srcset="{{ ${srcset} }}" ${media ? 'media="(' + media + ')"' : ''} sizes="{{ sizes }}" ${tp ? 'type="' + tp + '"' : ''}>`;
        } else {
            return `<source srcset="{{ static('img/stub.png') }} 1w" data-srcset="{{ ${srcset} }}" ${media ? 'media="(' + media + ')"' : ''} data-sizes="{{ sizes }}" ${tp ? 'type="' + tp + '"' : ''}>`;
        }
    };

    const sizesList = fixedSizes;
    const middleSize = sizesList[Math.floor(sizesList.length / 2)];

    let sizesStr = "";

    console.log(`
{% macro picture(data, alt, color="transparent", isReal=false, isLazy=true) %}
    ${generateSrcset('srcsetWebp3x', 'webp', 3 / 2)}

    ${generateSrcset('srcset3x', '', 3 / 2)}

    ${generateSrcset('srcsetWebp2x', 'webp', 4 / 3)}

    ${generateSrcset('srcset2x', '', 4 / 3)}

    ${generateSrcset('srcsetWebp1x', 'webp', 1)}

    ${generateSrcset('srcset1x', '', 1)}

    ${sizesStr}

    {% if (isReal) %}
        <picture >
            ${generateSource('srcsetWebp3x', 'min-resolution: 3dppx', 'image/webp', true)}
            ${generateSource('srcset3x', 'min-resolution: 3dppx', '', true)}
            ${generateSource('srcsetWebp2x', 'min-resolution: 2dppx', 'image/webp', true)}
            ${generateSource('srcset2x', 'min-resolution: 2dppx', '', true)}
            ${generateSource('srcsetWebp1x', '', 'image/webp', true)}
            ${generateSource('srcset1x', '', '', true)}
            <img src="{{ data.w${middleSize} }}" alt="{{ alt }}" style="background-color: {{ color }}">
        </picture>
    {% else %}
        <picture >
            ${generateSource('srcsetWebp3x', 'min-resolution: 3dppx', 'image/webp', false)}
            ${generateSource('srcset3x', 'min-resolution: 3dppx', '', false)}
            ${generateSource('srcsetWebp2x', 'min-resolution: 2dppx', 'image/webp', false)}
            ${generateSource('srcset2x', 'min-resolution: 2dppx', '', false)}
            ${generateSource('srcsetWebp1x', '', 'image/webp', false)}
            ${generateSource('srcset1x', '', '', false)}
            <img {% if isLazy %}data-lazy{% endif %} src="{{ static('img/stub.png') }}" data-src="{{ data.w${middleSize} }}" alt="{{ alt }}" style="background-color: {{ color }}">
        </picture>
    {% endif %}
{% endmacro %}`);


};


const generateData = (fixedSizes, color, w, h, fpath, fname, dstExt, dopData) => {

    const generateDataObject = (fieldMod, prefix, suffix) => {
        let res = '';
        for (let size in fixedSizes) {
            if (fixedSizes.hasOwnProperty(size)) {
                res +=
            `'w${fixedSizes[size]}${fieldMod ? fieldMod : ''}': staticfiles_storage.url('${prefix}@${fixedSizes[size]}.${suffix}'),
            `
            }
        }

        return res;
    };

    fname = fname.length ? fname : [fname];

    return fname.map((item, index) => {
        // const info = dopData[item] || [];

        // if (!dopData[item]) {
        //     console.log(item);
        // }

        return `        'image': {
            ${generateDataObject('', fpath + item, dstExt)}
            ${generateDataObject('webp', fpath + item, 'webp')}
            'color': '${color}',
            'aspect': ${w} / ${h}.0
        }` + (index < fname.length - 1 ? ',' : '');

        // return `        {'image': {
        //     ${generateDataObject('', fpath + item, dstExt)}
        //     ${generateDataObject('webp', fpath + item, 'webp')}
        //     'color': '${color}',
        //     'aspect': ${w} / ${h}.0
        // },
        // 'title': '${info[0]}',
        // 'place': '${info[1]}',
        // 'numbers': [{
        //     'label': '${info[2]}:',
        //     'value': '${info[3]}'
        // }, {
        //     'label': '${info[4]}:',
        //     'value': '${info[5]}',
        // }, {
        //     'label': '${info[6]}:',
        //     'value': '${info[7]}'
        // }]

        // }` + (index < fname.length - 1 ? ',' : '');
    });
}



// const sMob = 320; //size of mobile media
// const lMob = 320; //size of design psd for mobile

// const sDesk = 1024; //size of desktop media
// const lDesk = 1440; //size of design psd for desktop

// [lMob, sMob, 176, 'a']

const filterSizes = (fixedSizes, sizes) => {
    let min = 999999;
    let max = 0;

    for (let i = 0; i < sizes.length; i++) {
        const size = sizes[i];
        let minW = size.image.w;
        let maxW = size.image.w;

        if (size.tp === 'a') { //adaptive
            minW = size.layout.st * size.image.w / size.image.at;
            maxW = size.layout.ed * size.image.w / size.image.at;
        }

        min = Math.min(min, minW);
        max = Math.max(max, maxW);

        if (i === sizes.length - 1) {
            minW = maxW = (size.layout.ed + 1) * size.image.w / size.image.at;
            min = Math.min(min, minW);
            max = Math.max(max, maxW);
        }
    }

    max *= 2; //for 3x retina
    console.log(min, max);

    let addedLast = false;

    return fixedSizes.filter(item => {
        if (addedLast) return;
        if (item < min) return false;
        if (item >= max) {
            if (addedLast) {
                return false;
            } else {
                addedLast = true;
            }
        }
        return true
    })
}



const generateSizes = (sizes) => {
    let res = [];

    for (let i = 0; i < sizes.length; i++) {
        const size = sizes[i];
        let sizeStr = '';

        if (size.tp === 'a') { //adaptive
            sizeStr = Math.floor(10000 * size.image.w / size.image.at) / 100 + 'vw';
        }
        if (size.tp === 'f') { //fixed
            sizeStr = size.image.w + 'px';
        }

        res.push(`(max-width: ${size.layout.ed}px) ${sizeStr}`);

        if (i === sizes.length - 1) {
            res.push(Math.floor((size.layout.ed + 1) * size.image.w / size.image.at) + 'px');
        }
    }

    return [
        'sizes="' + res.join(', ') + '"',
    ].join(',\n');
}


const generateConvert = (fixedSizes, fpath, fname, srcExt, dstExt) => {

    const generateConvert = (srcExt, dstExt, fname, quality) => {
        let res = [];
        for (let size in fixedSizes) {
            if (fixedSizes.hasOwnProperty(size)) {
                res.push(`convert ${fname}.${srcExt} -resize ${fixedSizes[size]} -quality ${quality} ${fname}@${fixedSizes[size]}.${dstExt}`)
            }
        }

        return res;
    };


    fname = fname.length ? fname : [fname];

    let res = [];

    for (let i = 0; i < fname.length; i++) {
        res = res.concat(generateConvert(srcExt, dstExt, fname[i], 89));
        res = res.concat(generateConvert(srcExt, 'webp', fname[i], 89));
    }

    return res;
}





const generateAll = (fixedSizes, sizes, color, w, h, fpath, fname, srcExt, dstExt, dopData) => {
    let res = [];
    fixedSizes = filterSizes(fixedSizes, sizes);
    res = res.concat(fpath + (fname.length ? fname[0] : fname) + '\n');
    res = res.concat(generateData(fixedSizes, color, w, h, fpath, fname, dstExt, dopData));
    res = res.concat(generateSizes(sizes));
    res = res.concat(generateConvert(fixedSizes, fpath, fname, srcExt, dstExt));
    return res.concat('\n\n');
};

const fixedSizes = [120, 240, 400, 600, 800, 1024, 1280, 1440, 1680, 1920, 2160, 2560];
let res = [];


// const dopData = {
// "4-1": ["Город", "Владимир","Население","360 000","Проехали (км)","180","Дата","08.07.2019"],
// "4-2": ["Город", "Владимир","Население","360 000","Проехали (км)","180","Дата","08.07.2019"],
// "6-1": ["Город", "Нижний Новгород","Население","1 250 000","Проехали (км)","470","Дата","08.07.2019"],
// "3-1": ["Дорога", "Москва → Владимир","Расстояние (км)","180","Время в пути (ч)","3","Дата","08.07.2019"],
// "8-1": ["Город", "Казань","Население","1 250 000","Проехали (км)","890","Дата","11.07.2019"],
// "8-2": ["Город", "Казань","Население","1 250 000","Проехали (км)","890","Дата","11.07.2019"],
// "8-3": ["Город", "Казань","Население","1 250 000","Проехали (км)","890","Дата","11.07.2019"],
// "8-4": ["Город", "Казань","Население","1 250 000","Проехали (км)","890","Дата","11.07.2019"],
// "8-5": ["Город", "Казань","Население","1 250 000","Проехали (км)","890","Дата","11.07.2019"],
// "8-6": ["Город", "Казань","Население","1 250 000","Проехали (км)","890","Дата","11.07.2019"],
// "8-7": ["Город", "Казань","Население","1 250 000","Проехали (км)","890","Дата","11.07.2019"],
// "8-8": ["Город", "Казань","Население","1 250 000","Проехали (км)","890","Дата","11.07.2019"],
// "9-1": ["Город", "Свияжск","Население","250","Проехали (км)","960","Дата","12.07.2019"],
// "9-2": ["Город", "Свияжск","Население","250","Проехали (км)","960","Дата","12.07.2019"],
// "9-3": ["Город", "Свияжск","Население","250","Проехали (км)","960","Дата","12.07.2019"],
// "9-4": ["Город", "Свияжск","Население","250","Проехали (км)","960","Дата","12.07.2019"],
// "10-1": ["Город", "Иннополис","Население","400","Проехали (км)","990","Дата","12.07.2019"],
// "12-1": ["Город", "Болгар","Население","8 400","Проехали (км)","1170","Дата","13.07.2019"],
// "14-1": ["Город", "Тольятти","Население","720 000","Проехали (км)","1400","Дата","13.07.2019"],
// "16-1": ["Город", "Самара","Население","1 150 000","Проехали (км)","1525","Дата","13.07.2019"],
// "16-2": ["Город", "Самара","Население","1 150 000","Проехали (км)","1525","Дата","13.07.2019"],
// "16-3": ["Город", "Самара","Население","1 150 000","Проехали (км)","1525","Дата","13.07.2019"],
// "16-4": ["Город", "Самара","Население","1 150 000","Проехали (км)","1525","Дата","13.07.2019"],
// "16-5": ["Город", "Самара","Население","1 150 000","Проехали (км)","1525","Дата","13.07.2019"],
// "11-1": ["Дорога", "Казань → Болгар","Расстояние (км)","180","Время в пути (ч)","3","Дата","13.07.2019"],
// "13-1": ["Дорога", "Болгар → Тольятти","Расстояние (км)","230","Время в пути (ч)","4","Дата","13.07.2019"],
// "17-1": ["Дорога", "Самара → Уфа","Расстояние (км)","460","Время в пути (ч)","8","Дата","15.07.2019"],
// "20-1": ["Город", "Сатка","Население","42 000","Проехали (км)","2245","Дата","16.07.2019"],
// "20-2": ["Город", "Сатка","Население","42 000","Проехали (км)","2245","Дата","16.07.2019"],
// "22-1": ["Город", "Челябинск","Население","1 200 000","Проехали (км)","2445","Дата","16.07.2019"],
// "22-2": ["Город", "Челябинск","Население","1 200 000","Проехали (км)","2445","Дата","16.07.2019"],
// "22-3": ["Город", "Челябинск","Население","1 200 000","Проехали (км)","2445","Дата","16.07.2019"],
// "22-4": ["Город", "Челябинск","Население","1 200 000","Проехали (км)","2445","Дата","16.07.2019"],
// "19-1": ["Дорога", "Уфа → Сатка","Расстояние (км)","250","Время в пути (ч)","4","Дата","16.07.2019"],
// "24-1": ["Город", "Екатеринбург","Население","1 485 000","Проехали (км)","2730","Дата","18.07.2019"],
// "24-2": ["Город", "Екатеринбург","Население","1 485 000","Проехали (км)","2730","Дата","18.07.2019"],
// "24-3": ["Город", "Екатеринбург","Население","1 485 000","Проехали (км)","2730","Дата","18.07.2019"],
// "24-4": ["Город", "Екатеринбург","Население","1 485 000","Проехали (км)","2730","Дата","18.07.2019"],
// "24-5": ["Город", "Екатеринбург","Население","1 485 000","Проехали (км)","2730","Дата","18.07.2019"],
// "24-6": ["Город", "Екатеринбург","Население","1 485 000","Проехали (км)","2730","Дата","18.07.2019"],
// "23-1": ["Дорога", "Челябинск → Екатеринбург","Расстояние (км)","220","Время в пути (ч)","3","Дата","18.07.2019"],
// "23-2": ["Дорога", "Челябинск → Екатеринбург","Расстояние (км)","220","Время в пути (ч)","3","Дата","18.07.2019"],
// "26-1": ["Город", "Тюмень","Население","790 000","Проехали (км)","3070","Дата","21.07.2019"],
// "28-1": ["Город", "Омск","Население","1 165 000","Проехали (км)","3715","Дата","23.07.2019"],
// "28-2": ["Город", "Омск","Население","1 165 000","Проехали (км)","3715","Дата","23.07.2019"],
// "28-3": ["Город", "Омск","Население","1 165 000","Проехали (км)","3715","Дата","23.07.2019"],
// "28-4": ["Город", "Омск","Население","1 165 000","Проехали (км)","3715","Дата","23.07.2019"],
// "29-1": ["Дорога", "Омск → Барабинск","Расстояние (км)","340","Время в пути (ч)","6","Дата","23.07.2019"],
// "32-1": ["Город", "Новосибирск","Население","1 620 000","Проехали (км)","4405","Дата","24.07.2019"],
// "32-2": ["Город", "Новосибирск","Население","1 620 000","Проехали (км)","4405","Дата","24.07.2019"],
// "31-1": ["Дорога", "Барабинск → Новосибирск","Расстояние (км)","320","Время в пути (ч)","5","Дата","24.07.2019"],
// "33-1": ["Район Новосибирска", "Академгородок","Население","50 000","Проехали (км)","4435","Дата","26.07.2019"],
// "33-2": ["Район Новосибирска", "Академгородок","Население","50 000","Проехали (км)","4435","Дата","26.07.2019"],
// "35-1": ["Сибирский федеральный округ", "Алтай","Население","2 315 000","Проехали (км)","4995","Дата","26.07.2019"],
// "35-2": ["Сибирский федеральный округ", "Алтай","Население","2 315 000","Проехали (км)","4995","Дата","26.07.2019"],
// "35-3": ["Сибирский федеральный округ", "Алтай","Население","2 315 000","Проехали (км)","4995","Дата","26.07.2019"],
// "35-4": ["Сибирский федеральный округ", "Алтай","Население","2 315 000","Проехали (км)","4995","Дата","26.07.2019"],
// "35-5": ["Сибирский федеральный округ", "Алтай","Население","2 315 000","Проехали (км)","4995","Дата","26.07.2019"],
// "35-6": ["Сибирский федеральный округ", "Алтай","Население","2 315 000","Проехали (км)","4995","Дата","26.07.2019"],
// "34-1": ["Дорога", "Новосибирск → Топольное, Алтайский край","Расстояние (км)","500","Время в пути (ч)","8","Дата","26.07.2019"],
// "36-1": ["Дорога", "Топольное, Алтайский край → Бийск","Расстояние (км)","180","Время в пути (ч)","3","Дата","27.07.2019"],
// "36-2": ["Дорога", "Топольное, Алтайский край → Бийск","Расстояние (км)","180","Время в пути (ч)","3","Дата","27.07.2019"],
// "36-3": ["Дорога", "Топольное, Алтайский край → Бийск","Расстояние (км)","180","Время в пути (ч)","3","Дата","27.07.2019"],
// "39-1": ["Город", "Кемерово","Население","550 000","Проехали (км)","5645","Дата","28.07.2019"],
// "39-2": ["Город", "Кемерово","Население","550 000","Проехали (км)","5645","Дата","28.07.2019"],
// "38-1": ["Дорога", "Бийск → Кемерово","Расстояние (км)","470","Время в пути (ч)","8","Дата","28.07.2019"],
// "38-2": ["Дорога", "Бийск → Кемерово","Расстояние (км)","470","Время в пути (ч)","8","Дата","28.07.2019"],
// "41-1": ["Город", "Красноярск","Население","1 095 000","Проехали (км)","6200","Дата","29.07.2019"],
// "41-2": ["Город", "Красноярск","Население","1 095 000","Проехали (км)","6200","Дата","29.07.2019"],
// "41-3": ["Город", "Красноярск","Население","1 095 000","Проехали (км)","6200","Дата","29.07.2019"],
// "40-1": ["Дорога", "Кемерово → Красноярск","Расстояние (км)","530","Время в пути (ч)","9","Дата","29.07.2019"],
// "40-2": ["Дорога", "Кемерово → Красноярск","Расстояние (км)","530","Время в пути (ч)","9","Дата","29.07.2019"],
// "42-1": ["Город", "Дивногорск","Население","30 000","Проехали (км)","6280","Дата","30.07.2019"],
// "42-2": ["Город", "Дивногорск","Население","30 000","Проехали (км)","6280","Дата","30.07.2019"],
// "44-1": ["Город", "Канск","Население","90 000","Проехали (км)","6510","Дата","02.08.2019"],
// "44-2": ["Город", "Канск","Население","90 000","Проехали (км)","6510","Дата","02.08.2019"],
// "46-1": ["Город", "Нижнеудинск","Население","34 000","Проехали (км)","6830","Дата","02.08.2019"],
// "43-1": ["Дорога", "Красноярск → Канск","Расстояние (км)","230","Время в пути (ч)","4","Дата","02.08.2019"],
// "48-1": ["Город", "Тулун","Население","40 000","Проехали (км)","6950","Дата","03.08.2019"],
// "48-2": ["Город", "Тулун","Население","40 000","Проехали (км)","6950","Дата","03.08.2019"],
// "51-1": ["Город", "Иркутск","Население","620 000","Проехали (км)","7380","Дата","03.08.2019"],
// "51-2": ["Город", "Иркутск","Население","620 000","Проехали (км)","7380","Дата","03.08.2019"],
// "51-3": ["Город", "Иркутск","Население","620 000","Проехали (км)","7380","Дата","03.08.2019"],
// "47-1": ["Дорога", "Нижнеудинск → Тулун","Расстояние (км)","120","Время в пути (ч)","2","Дата","03.08.2019"],
// "49-1": ["Дорога", "Тулун → Иркутск","Расстояние (км)","400","Время в пути (ч)","7","Дата","03.08.2019"],
// "49-2": ["Дорога", "Тулун → Иркутск","Расстояние (км)","400","Время в пути (ч)","7","Дата","03.08.2019"],
// "53-1": ["Город", "Улан-Удэ","Население","435 000","Проехали (км)","7830","Дата","06.08.2019"],
// "53-2": ["Город", "Улан-Удэ","Население","435 000","Проехали (км)","7830","Дата","06.08.2019"],
// "53-3": ["Город", "Улан-Удэ","Население","435 000","Проехали (км)","7830","Дата","06.08.2019"],
// "53-4": ["Город", "Улан-Удэ","Население","435 000","Проехали (км)","7830","Дата","06.08.2019"],
// "53-5": ["Город", "Улан-Удэ","Население","435 000","Проехали (км)","7830","Дата","06.08.2019"],
// "53-6": ["Город", "Улан-Удэ","Население","435 000","Проехали (км)","7830","Дата","06.08.2019"],
// "52-1": ["Дорога", "Иркутск → Улан-Удэ. Озеро Байкал","Расстояние (км)","450","Время в пути (ч)","10","Дата","06.08.2019"],
// "52-2": ["Дорога", "Иркутск → Улан-Удэ. Озеро Байкал","Расстояние (км)","450","Время в пути (ч)","10","Дата","06.08.2019"],
// "55-1": ["Город", "Петровск-Забайкальский","Население","16 000","Проехали (км)","7980","Дата","07.08.2019"],
// "55-2": ["Город", "Петровск-Забайкальский","Население","16 000","Проехали (км)","7980","Дата","07.08.2019"],
// "55-3": ["Город", "Петровск-Забайкальский","Население","16 000","Проехали (км)","7980","Дата","07.08.2019"],
// "57-1": ["Город", "Чита","Население","350 000","Проехали (км)","8440","Дата","07.08.2019"],
// "54-1": ["Дорога", "Улан-Удэ → Петровск-Забайкальский","Расстояние (км)","150","Время в пути (ч)","4","Дата","07.08.2019"],
// "54-2": ["Дорога", "Улан-Удэ → Петровск-Забайкальский","Расстояние (км)","150","Время в пути (ч)","4","Дата","07.08.2019"],
// "54-3": ["Дорога", "Улан-Удэ → Петровск-Забайкальский","Расстояние (км)","150","Время в пути (ч)","4","Дата","07.08.2019"],
// "56-1": ["Дорога", "Петровск-Забайкальский → Чита","Расстояние (км)","460","Время в пути (ч)","7","Дата","07.08.2019"],
// "56-2": ["Дорога", "Петровск-Забайкальский → Чита","Расстояние (км)","460","Время в пути (ч)","7","Дата","07.08.2019"],
// "59-1": ["Поселок городского типа", "Ерофей Павлович","Население","4500","Проехали (км)","9240","Дата","08.08.2019"],
// "59-2": ["Поселок городского типа", "Ерофей Павлович","Население","4500","Проехали (км)","9240","Дата","08.08.2019"],
// "58-1": ["Дорога", "Чита → Ерофей Павлович","Расстояние (км)","800","Время в пути (ч)","12","Дата","08.08.2019"],
// "58-2": ["Дорога", "Чита → Ерофей Павлович","Расстояние (км)","800","Время в пути (ч)","12","Дата","08.08.2019"],
// "58-3": ["Дорога", "Чита → Ерофей Павлович","Расстояние (км)","800","Время в пути (ч)","12","Дата","08.08.2019"],
// "58-4": ["Дорога", "Чита → Ерофей Павлович","Расстояние (км)","800","Время в пути (ч)","12","Дата","08.08.2019"],
// "58-5": ["Дорога", "Чита → Ерофей Павлович","Расстояние (км)","800","Время в пути (ч)","12","Дата","08.08.2019"],
// "58-6": ["Дорога", "Чита → Ерофей Павлович","Расстояние (км)","800","Время в пути (ч)","12","Дата","08.08.2019"],
// "58-7": ["Дорога", "Чита → Ерофей Павлович","Расстояние (км)","800","Время в пути (ч)","12","Дата","08.08.2019"],
// "61-1": ["Город", "Благовещенск","Население","225 000","Проехали (км)","10 020","Дата","09.08.2019"],
// "60-1": ["Дорога", "Ерофей Павлович → Благовещенск","Расстояние (км)","780","Время в пути (ч)","12","Дата","09.08.2019"],
// "62-1": ["Город", "Хэйхэ","Население","1 750 000","Проехали (км)","10 020","Дата","10.08.2019"],
// "62-2": ["Город", "Хэйхэ","Население","1 750 000","Проехали (км)","10 020","Дата","10.08.2019"],
// "62-3": ["Город", "Хэйхэ","Население","1 750 000","Проехали (км)","10 020","Дата","10.08.2019"],
// "64-1": ["Город", "Хабаровск","Население","617 000","Проехали (км)","10 750","Дата","11.08.2019"],
// "64-2": ["Город", "Хабаровск","Население","617 000","Проехали (км)","10 750","Дата","11.08.2019"],
// "63-1": ["Дорога", "Благовещенск → Хабаровск","Расстояние (км)","730","Время в пути (ч)","11","Дата","11.08.2019"],
// "66-1": ["Город", "Владивосток","Население","605 000","Проехали (км)","11 650","Дата","13.08.2019"],
// "66-2": ["Город", "Владивосток","Население","605 000","Проехали (км)","11 650","Дата","13.08.2019"],
// "66-3": ["Город", "Владивосток","Население","605 000","Проехали (км)","11 650","Дата","13.08.2019"],
// "66-4": ["Город", "Владивосток","Население","605 000","Проехали (км)","11 650","Дата","13.08.2019"],
// "66-5": ["Город", "Владивосток","Население","605 000","Проехали (км)","11 650","Дата","13.08.2019"],
// "6-2": ["Город", "Нижний Новгород","Население","1 250 000","Проехали (км)","470","Дата","08.07.2020"],
// "19-2": ["Дорога", "Уфа → Сатка","Расстояние (км)","250","Время в пути (ч)","4","Дата","16.07.2020"],
// "63-2": ["Дорога", "Благовещенск → Хабаровск","Расстояние (км)","730","Время в пути (ч)","11","Дата","11.08.2020"],
// "6-3": ["Город", "Нижний Новгород","Население","1 250 000","Проехали (км)","470","Дата","08.07.2021"],
// "19-3": ["Дорога", "Уфа → Сатка","Расстояние (км)","250","Время в пути (ч)","4","Дата","16.07.2021"]
// }


// generateMacros(fixedSizes);

//first array is array of design layouts
//here is two 320-1024 and 1024-inf
//each array has 3 items:
// -screen start size (lasts till next screen start size)
// -image size at screen start
// -type: 'f' fixed or 'a' adaptive (stretching)
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 176, 'a'], [lDesk, sDesk, 298, 'a']], '#fff', 2139, 2953, 'img/index/cover/', 'cover', 'png', 'jpg'));
//for flow
// res = res.concat(generateAll(fixedSizes, [[lDesk, sDesk, 160, 'a']], '#fff', 2560, 1707, 'img/index/photos/', ['3-1','4-1','4-2','6-1','6-2','6-3','8-1','8-2','8-3','8-4','8-5','8-6','8-7','8-8','9-1','9-2','9-3','9-4','10-1','11-1','12-1','13-1','14-1','16-1','16-2','16-3','16-4','16-5','17-1','19-1','19-2','19-3','20-1','20-2','22-1','22-2','22-3','22-4','23-1','23-2','24-1','24-2','24-3','24-4','24-5','24-6','26-1','28-1','28-2','28-3','28-4','29-1','31-1','32-1','32-2','33-1','33-2','34-1','35-1','35-2','35-3','35-4','35-5','35-6','36-1','36-2','36-3','38-1','38-2','39-1','39-2','40-1','40-2','41-1','41-2','41-3','42-1','42-2','43-1','44-1','44-2','46-1','47-1','48-1','48-2','49-1','49-2','51-1','51-2','51-3','52-1','52-2','53-1','53-2','53-3','53-4','53-5','53-6','54-1','54-2','54-3','55-1','55-2','55-3','56-1','56-2','57-1','58-1','58-2','58-3','58-4','58-5','58-6','58-7','59-1','59-2','60-1','61-1','62-1','62-2','62-3','63-1','63-2','64-1','64-2','66-1','66-2','66-3','66-4','66-5'], 'jpg', 'jpg', dopData));
//for zoom
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 510, 'a'], [lDesk, sDesk, 1440, 'a']], '#fff', 2560, 1707, 'img/index/photos/', 'image', 'jpg', 'jpg'));

// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 200, 'a'], [lDesk, sDesk, 200, 'a']], '#fff', 800, 800, 'img/index/authors/', 'author', 'png', 'jpg', 2));

// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 241, 'a'], [lDesk, sDesk, 497, 'a']], '#fff', 1988, 1680, 'img/index/book/', 'book', 'png', 'jpg'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 280, 'a'], [lDesk, sDesk, 280, 'a']], '#fff', 1595, 1027, 'img/index/book/', 'feature', 'jpg', 'jpg', 2));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 280, 'a'], [lDesk, sDesk, 600, 'a']], '#fff', 4535, 3646, 'img/index/book/', ['es-inside-v1','es-inside-v2','es-inside-v3'], 'jpg', 'jpg'));

// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 63.68715084, 'a'], [lDesk, sDesk, 63.68715084, 'a']], '#fff', 570, 571, 'img/index/inside/1/', ['1'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 68.37988827, 'a'], [lDesk, sDesk, 68.37988827, 'a']], '#fff', 612, 652, 'img/index/inside/1/', ['2'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 63.01675978, 'a'], [lDesk, sDesk, 63.01675978, 'a']], '#fff', 564, 596, 'img/index/inside/1/', ['3'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 55.6424581, 'a'], [lDesk, sDesk, 55.6424581, 'a']], '#fff', 498, 682, 'img/index/inside/1/', ['4'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 65.58659218, 'a'], [lDesk, sDesk, 65.58659218, 'a']], '#fff', 587, 588, 'img/index/inside/1/', ['5'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 53.29608939, 'a'], [lDesk, sDesk, 53.29608939, 'a']], '#fff', 477, 603, 'img/index/inside/1/', ['6'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 66.36871508, 'a'], [lDesk, sDesk, 66.36871508, 'a']], '#fff', 594, 540, 'img/index/inside/1/', ['7'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 65.58659218, 'a'], [lDesk, sDesk, 65.58659218, 'a']], '#fff', 587, 599, 'img/index/inside/1/', ['8'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 76.08938547, 'a'], [lDesk, sDesk, 76.08938547, 'a']], '#fff', 681, 280, 'img/index/inside/1/', ['9'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 86.48044693, 'a'], [lDesk, sDesk, 86.48044693, 'a']], '#fff', 774, 417, 'img/index/inside/1/', ['10'], 'png', 'png'));
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 81.00558659, 'a'], [lDesk, sDesk, 81.00558659, 'a']], '#fff', 725, 516, 'img/index/inside/1/', ['11'], 'png', 'png'));

// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 160, 'a'], [lDesk, sDesk, 160, 'a']], '#fff', 1748, 1240, 'img/index/inside/2/', ['1', '2', '3', '4'], 'png', 'jpg'));

// res = res.concat(generateAll(fixedSizes, [{layout: {st: 320, ed: 1023}, image: {at: 320, w: 200}, tp: 'a'}, {layout: {st: 1024, ed: 1919}, image: {at: 1440, w: 200}, tp: 'a'}], '#fff', 800, 1120, 'img/index/inside/3/', ['1', '2'], 'png', 'jpg'));

// res = res.concat(generateAll(fixedSizes, [{layout: {st: 320, ed: 1023}, image: {at: 320, w: 245}, tp: 'a'}, {layout: {st: 1024, ed: 1919}, image: {at: 1440, w: 245}, tp: 'a'}], '#fff', 980, 700, 'img/index/inside/4/', ['1', '2'], 'png', 'jpg'));

//for small gallery
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 280, 'a'], [lDesk, sDesk, 600, 'a']], '#fff', 2847, 2010, 'img/index/inside/', ['image3'], 'jpg', 'jpg'));
//for fullscreen gallery
// res = res.concat(generateAll(fixedSizes, [[lMob, sMob, 680, 'a'], [lDesk, sDesk, 1080, 'a']], '#fff', 2847, 2010, 'img/index/inside/', 'image', 'jpg', 'jpg', 8));

// res = res.concat(generateAll(fixedSizes, [[lDesk, sDesk, 128, 'a']], '#fff', 2132, 2957, 'img/order/cover/', 'cover', 'png', 'jpg'));
// /* eslint-enable */

// console.log(res.join('\n'));