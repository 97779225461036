'use strict';

var _ = require('underscore/underscore');
var BasePopup = require('front/components/BasePopup/BasePopup');

var template = require('./GalleryPopup.jinja');
require('./GalleryPopup.less');

var CommonGallery = require('front/components/CommonGallery/CommonGallery');
var CommonGalleryMobile = require('front/components/CommonGalleryMobile/CommonGalleryMobile');


module.exports = BasePopup.extend({

    template: template,

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER:  _.extend({

    }, BasePopup.prototype.VIEWS_TO_REGISTER),

    events: _.extend({

    }, BasePopup.prototype.baseEvents),


    apiUrl: function () {
        return window.UrlsAPI['api:gallery-popup']('json');
    },

    renderData: function () {
        return this.data || {};
    },

    initialize: function (options) {
        this.options = options || {};

        this.startIndex = (parseInt(options.params, 10) || 1) - 1;

        this.showLoader = true;

        BasePopup.prototype.initialize.call(this, options);
    },

    onResize: function () {
        if (app.settings.isMobile) {
            this.$('.BasePopup-content').css('min-height', '0px');
            this.$('.BasePopup-content').css('height', window.innerHeight);
        }

        BasePopup.prototype.onResize.call(this);
    },

    activate: function () {
        return BasePopup.prototype.activate.call(this).then(() => {
            this.commonGallery = new CommonGallery({
                el: this.$('.CommonGallery')[0],
                startIndex: this.startIndex
            });

            this.commonGalleryMobile = new CommonGalleryMobile({
                el: this.$('.CommonGalleryMobile')[0],
                startIndex: this.startIndex
            });

            app.vent.on('close-gallery', this.close);

            this.commonGallery.on('index-changed', (index) => {
                Backbone.history.navigate(window.Urls['front:gallery-popup'](index + 1), {trigger: false});
            });

            this.commonGalleryMobile.on('index-changed', (index) => {
                Backbone.history.navigate(window.Urls['front:gallery-popup'](index + 1), {trigger: false});
            });

            // this.commonGallery.goTo(this.startIndex);
            // this.CommonGalleryMobile.goTo(this.startIndex);
        });
    },


    deactivate: function (params) {
        this.commonGallery && this.commonGallery.destroy();

        this.commonGalleryMobile && this.commonGalleryMobile.destroy();

        app.vent.off('close-gallery', this.close);

        this.commonGallery.off('index-changed');

        this.commonGalleryMobile.off('index-changed');

        return BasePopup.prototype.deactivate.call(this, params);
    }
});
