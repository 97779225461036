'use strict';

var _ = require('underscore/underscore');
var BasePopup = require('front/components/BasePopup/BasePopup');

var template = require('./DonatePopup.jinja');
require('./DonatePopup.less');
require('jquery.numeric');

module.exports = BasePopup.extend({

    template: template,

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: _.extend({

    }, BasePopup.prototype.VIEWS_TO_REGISTER),

    events: _.extend({
        'click .DonatePopup-price-preset': 'onPresetClick',
        'change .DonatePopup-input input[name="amount"]': 'onPriceUpdate',
        'input .DonatePopup-input input[name="amount"]': 'onPriceUpdate',
        'click .DonatePopup-footer-pay': 'onPayClick',
        'input input, textarea': 'onTextareaInputChange',
        'input .DonatePopup-input input[name="name"]': 'onNameInputChange',
        'input .DonatePopup-input input[name="email"]': 'onEmailInputChange'
    }, BasePopup.prototype.baseEvents),


    apiUrl: function () {
        return window.UrlsAPI['api:donate-popup']('json');
    },

    renderData: function () {
        return this.data || {};
    },

    initialize: function (options) {
        this.options = options || {};

        this.showLoader = true;

        window.app.connectTinkoff();

        BasePopup.prototype.initialize.call(this, options);
    },

    activate: function () {
        return BasePopup.prototype.activate.call(this).then(() => {
            this.$('.DonatePopup-input input[name="amount"]').numeric(
                { decimal: false, negative: false },
                function () {
                    this.value = '';
                    this.focus();
                }
            );

            this.onNameInputChange();
            this.onEmailInputChange();

            this.restoreState();

            this.$textarea = this.$('textarea[name="comment"]');

            this.$textarea.css({
                'height': this.$textarea[0].scrollHeight,
                'overflow-y': 'hidden'
            });

            this.$textarea.on('input', () => {
                this.$textarea[0].style.height = 'auto';
                this.$textarea[0].style.height = (this.$textarea[0].scrollHeight) + 'px';
            });
        });
    },

    onResize: function () {
        this.$textarea && this.$textarea.trigger('input');
    },

    deactivate: function (params) {
        this.saveState();

        this.$textarea.off('input');

        return BasePopup.prototype.deactivate.call(this, params);
    },

    restoreState: function () {
        if (!app.state.donateState) {
            return;
        }

        this.$('.DonatePopup-input input[name="amount"]').val(app.state.donateState.amount || '');
        this.onPriceUpdate();
    },

    saveState: function () {
        app.state.donateState = this.getFormData();
    },

    onPresetClick: function (e) {
        const $preset = $(e.currentTarget);
        let price = $preset.attr('data-value');

        price = price.replace(/ /g, '');

        this.$('.DonatePopup-input input[name="amount"]').val(price);

        this.onPriceUpdate();
    },

    onNameInputChange: function (e) {
        if (e && e.target.value.length > 0 || this.$('.DonatePopup-input input[name="email"]').val().length > 0) {
            this.$('.DonatePopup-agreed_personal-checkbox').addClass('active');
        } else {
            this.$('.DonatePopup-agreed_personal-checkbox').removeClass('active');
        }
    },

    onEmailInputChange: function (e) {
        if (e && e.target.value.length > 0 || this.$('.DonatePopup-input input[name="name"]').val().length > 0) {
            this.$('.DonatePopup-agreed_personal-checkbox').addClass('active');
        } else {
            this.$('.DonatePopup-agreed_personal-checkbox').removeClass('active');
        }
    },

    onPriceUpdate: function () {
        let price = this.$('.DonatePopup-input input[name="amount"]').val() - 0;

        var regExp = new RegExp('\\B(?=(\\d{' + 3 + '})+(?!\\d))', 'g');

        const currency = this.$('.DonatePopup-footer-pay span').attr('data-currency');
        let buttonPrice = '';

        if (price > 0) {
            buttonPrice = (price).toString().replace(regExp, ' ') + ' ' + currency;
        }

        this.$('.DonatePopup-footer-pay span').text(buttonPrice);
    },

    getFormData: function () {
        let data = {
            amount: this.$('.DonatePopup-input input[name="amount"]').val() - 0,
            name: this.$('.DonatePopup-input input[name="name"]').val(),
            email: this.$('.DonatePopup-input input[name="email"]').val(),
            comment: this.$('.DonatePopup-input textarea[name="comment"]').val()
        };

        return data;
    },

    onTextareaInputChange: function () {
        this.$('.DonatePopup-error').hide();
    },

    showError: function (field) {
        if (field === 'agreed') {
            this.$('.DonatePopup-agreed-checkbox').flashClass('error', 500);
        } else if (field === 'agreed_personal') {
            this.$('.DonatePopup-agreed_personal-checkbox').flashClass('error', 500);
        } else {
            const $input = this.$('.DonatePopup-wrapper input[name="' + field + '"]');
            const $inputWrapper = $input.parent();
            this.$('.DonatePopup-error').show()
                .appendTo($inputWrapper);

            app.utils.animateWindowScroll($inputWrapper.offset().top - 100 * app.settings.scale, 500);

            clearTimeout(this.errorFocusTimeout);

            this.errorFocusTimeout = setTimeout(() => {
                $input.focus();
            }, 500);
        }
    },

    sendFormToPayService: function (res) {
        this.$('.TinkoffPayForm input[name="amount"]').val(res.amount);
        this.$('.TinkoffPayForm input[name="order"]').val(res.id);
        this.$('.TinkoffPayForm input[name="name"]').val(res.name);
        this.$('.TinkoffPayForm input[name="email"]').val(res.email);
        window.pay(this.$('.TinkoffPayForm form')[0]);

        localStorage.setItem('orderId', res.id);
        localStorage.setItem('email', res.email);
    },


    onPayClick: function () {
        if (this.isSending) {
            return;
        }

        const data = this.getFormData();
        const agreed = this.$('.DonatePopup-agreed input[name="agreed"]').is(':checked');
        const agreedPersonal = this.$('.DonatePopup-agreed input[name="agreed_personal"]').is(':checked');

        if (isNaN(data.amount) || !data.amount || data.amount < 1) {
            this.showError('amount');

            return;
        }

        if (!agreed) {
            this.showError('agreed');

            return;
        }

        if (!agreedPersonal && (this.$('.DonatePopup-input input[name="name"]').val().length > 0 || this.$('.DonatePopup-input input[name="email"]').val().length > 0)) {
            this.showError('agreed_personal');

            return;
        }

        this.isSending = true;
        this.$el.css('pointer-events', 'none');

        //block ui for 3 second to prevent accidental double send
        //pointer events blocks only mouse, thus set isSending flag
        //to block input changes by keyboard and send button click by enter
        setTimeout(() => {
            this.$el.css('pointer-events', '');
            this.isSending = false;
        }, 3000);


        this._xhr = $.ajax(window.UrlsAPI['api:donate-popup']('json'), {
            method: 'POST',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(data),
            'success': function (res) {
                this.sendFormToPayService(res);
            }.bind(this),
            'error': function (error) {
                console.log(error);
                alert('Произошла ошибка при оформлении заказа. Пожалуйста, свяжитесь с нами для решения проблемы. anybody@sencessence.ru');
            }.bind(this)
        });
    }
});
