'use strict';

// var _ = require('underscore/underscore');
var Section = require('front/pages/Section/Section');

require('./Index.less');


var IndexAuthors = require('front/components/IndexAuthors/IndexAuthors');
var IndexBook = require('front/components/IndexBook/IndexBook');
var IndexCover = require('front/components/IndexCover/IndexCover');
var IndexCredits = require('front/components/IndexCredits/IndexCredits');
var IndexFeedback = require('front/components/IndexFeedback/IndexFeedback');
var IndexFooter = require('front/components/IndexFooter/IndexFooter');
var IndexHelp = require('front/components/IndexHelp/IndexHelp');
var IndexHero = require('front/components/IndexHero/IndexHero');
var IndexInside = require('front/components/IndexInside/IndexInside');
var IndexProcess = require('front/components/IndexProcess/IndexProcess');
var IndexLoader = require('front/components/IndexLoader/IndexLoader');
var IndexRoute = require('front/components/IndexRoute/IndexRoute');

module.exports = Section.extend({

    el: '.Index',

    mainContentSelector: '.Index-content',

    events: {
    },

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: {
        'IndexAuthors': {selector: '.IndexAuthors', viewConstructor: IndexAuthors},
        'IndexBook': {selector: '.IndexBook', viewConstructor: IndexBook},
        'IndexCover': {selector: '.IndexCover', viewConstructor: IndexCover},
        'IndexCredits': {selector: '.IndexCredits', viewConstructor: IndexCredits},
        'IndexFeedback': {selector: '.IndexFeedback', viewConstructor: IndexFeedback},
        'IndexFooter': {selector: '.IndexFooter', viewConstructor: IndexFooter},
        'IndexHelp': {selector: '.IndexHelp', viewConstructor: IndexHelp},
        'IndexHero': {selector: '.IndexHero', viewConstructor: IndexHero},
        'IndexInside': {selector: '.IndexInside', viewConstructor: IndexInside},
        'IndexProcess': {selector: '.IndexProcess', viewConstructor: IndexProcess},
        'IndexLoader': {selector: '.IndexLoader', viewConstructor: IndexLoader},
        'IndexRoute': {selector: '.IndexRoute', viewConstructor: IndexRoute}
    },


    initialize: function (options) {
        this.options = options || {};

        app.vent.on('loaded', () => {
            this.$('.Index-contentWrapper').removeClass('loading');
            this.$('.Index-order-button').addClass('show');

            setTimeout(() => {
                this.$('.Index-order-button').addClass('shown');
            }, 3000);

            app.state.forceResize = true;
            $(window).resize();
        });


        // app.vent.on('resize', () => {
        //     this.$('.Index-rightColumn').css('margin-top', -this.$('.IndexCover').height());
        // });


        app.vent.on('reach-bottom', state => {
            this.$('.Index-order-button').toggleClass('show', !state);
        });

        Section.prototype.initialize.call(this, options);
    }
});
