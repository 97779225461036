'use strict';

var Base = require('front/components/Base/Base');

require('./IndexFeedback.less');
require('./IndexFeedbackItem.less');

var IndexFeedbackItem = require('front/components/IndexFeedback/IndexFeedbackItem.js');
var IndexFeedbackItemTemplate = require('front/components/IndexFeedback/IndexFeedbackItemRuntime.jinja');


module.exports = Base.extend({

    events: {
        'click .IndexFeedback-button-more': 'onMoreButtonClick'
    },

    initialize: function (options) {
        this.options = options || {};

        // app.vent.on('loaded', () => {
        //     window.scrollTo(0, this.$el.offset().top);
        // });


        app.vent.on('loaded', () => {
            this.headerAnimator = new app.utils.HeaderAnimator(this.$('h2'));

            this.getNextData({
                showLoading: false
            });

            this.$('.IndexFeedbackItem').each((index, item) => {
                new IndexFeedbackItem({
                    el: item
                });
            });
        });

        this.$('h2').onCustom('screen-enter', (e, data) => {
            this.headerAnimator.fire(app.settings.showDelay);
            this.headerAnimator = null;
            data.$el.offCustom('screen-enter');
        });


        this.$('.IndexFeedback-buttons-wrapper').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });

        const hasMore = !this.$('.IndexFeedback-button-more').hasClass('no-more');
        const offset = this.$el.attr('data-items-count');

        this.state = {
            next: hasMore ? window.UrlsAPI['api:feedback-list']('json') + '?offset=' + offset : ''
        };



        app.vent.on('scroll-to-feedback', () => {
            const shift = app.sizes.isPhoneLayout ? 150 : 300;
            app.utils.animateWindowScroll(this.$('.IndexFeedback-list').offset().top - shift * app.settings.scale, 1000);
        });


        Base.prototype.initialize.call(this, options);
    },


    getNextData: function (params) {
        if (!this.state.next) {
            return;
        }

        if (params.showLoading) {
            this.$('.IndexFeedback-button-more').addClass('loading');
        }

        if (this.loading) {
            return;
        }

        this.loading = true;

        this._xhr = $.ajax(this.state.next, {
            method: 'GET',
            contentType: 'application/json; charset=utf-8',
            'success': (data) => {
                this.state.next = data.next;
                this.results = data.results;
                this.$('.IndexFeedback-button-more').removeClass('loading');
                this.loading = false;
                if (this.showAfterLoad) {
                    this.onMoreButtonClick();
                }
            },
            'error': data => {
                console.log(data);
                this.$('.IndexFeedback-button-more').removeClass('loading');
                this.loading = false;
            }
        });
    },


    onMoreButtonClick: function () {
        if (!this.results) {
            this.showAfterLoad = true;

            return;
        }

        this.showAfterLoad = false;

        const feedback = [];

        this.results.forEach(item => {
            var $item = $(IndexFeedbackItemTemplate.render({data: item}));
            this.$('.IndexFeedback-list').append($item);
            feedback.push($item);
            app.utils.updateLinksAttributes($item);

            new IndexFeedbackItem({
                el: $item[0]
            });
        });

        setTimeout(() => {
            feedback.forEach(($item) => {
                $item.addClass('show');
            });
        }, 17);

        this.results = undefined;

        this.$('.IndexFeedback-button-more').toggleClass('no-more', !this.state.next);

        app.vent.trigger('page-height-changed');

        this.getNextData({
            showLoading: true
        });
    }

});
