var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/sencessence.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/BasePopup/BasePopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/BasePopup/BasePopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n\n<aside class=\"BasePopup BasePopup--visible ";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popupClass"))(env, context, frame, runtime, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
output += t_4;
output += "\" data-meta-title=\"";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popupMetaTitle"))(env, context, frame, runtime, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
output += t_6;
output += "\">\n\n    <div class=\"BasePopup-panel\">\n        <button class=\"BasePopup-close\">\n            ";
output += runtime.suppressValue((lineno = 7, colno = 36, runtime.callWrap(runtime.memberLookup((t_1),"svgSprite"), "macros[\"svgSprite\"]", context, ["popup-close","0 0 58 58",runtime.makeKeywordArgs({"caller": (function (){var macro_t_8 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_9 = "";;
frame = frame.pop();
return new runtime.SafeString(t_9);
});
return macro_t_8;})()})])), env.opts.autoescape);
output += "\n        </button>\n    </div>\n\n    ";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popupTopLayer"))(env, context, frame, runtime, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
output += t_10;
output += "\n\n    <div class=\"BasePopup-bg\"></div>\n\n    <div class=\"BasePopup-wrapper\">\n        <article class=\"BasePopup-content\">\n            ";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popupContent"))(env, context, frame, runtime, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
output += t_12;
output += "\n        </article>\n    </div>\n</aside>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popupClass(env, context, frame, runtime, cb) {
var lineno = 3;
var colno = 46;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popupMetaTitle(env, context, frame, runtime, cb) {
var lineno = 3;
var colno = 101;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popupTopLayer(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 7;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popupContent(env, context, frame, runtime, cb) {
var lineno = 17;
var colno = 15;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_popupClass: b_popupClass,
b_popupMetaTitle: b_popupMetaTitle,
b_popupTopLayer: b_popupTopLayer,
b_popupContent: b_popupContent,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/BasePopup/BasePopup.jinja"] , dependencies)