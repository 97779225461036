'use strict';

var _ = require('underscore/underscore');
var Base = require('front/components/Base/Base');

require('./CommonGalleryMobile.less');

module.exports = Base.extend({

    events: {
        'click .CommonGalleryMobile-thumb': 'onThumbClick'
    },

    initialize: function (options) {
        this.options = options || {};

        _.bindAll(this, 'onResizeLocal');

        this.index = options.startIndex || 0;

        this.zIndex = 0;

        this.photosCount =  this.$('.CommonGalleryMobile-image').length;

        if (this.index >= this.photosCount) {
            this.index = 0;
        }

        this.showImage(this.index, false);

        const $activeThumb = this.$('.CommonGalleryMobile-thumb.active');
        const activeThumbWidth = $activeThumb.width();
        const activeThumbOffset = $activeThumb.offset().left;

        this.$('.CommonGalleryMobile-thumbs').scrollLeft(activeThumbOffset - app.sizes.windowWidth / 2 + activeThumbWidth / 2);

        $(window).on('resize', this.onResizeLocal);

        setTimeout(this.onResizeLocal, 34);

        Base.prototype.initialize.call(this, options);
    },


    onResizeLocal: function () {
        const $image = this.$('.CommonGalleryMobile-image').eq(this.index);
        const $wrapper = $('.CommonGalleryMobile-image-wrapper', $image);
        const $img = this.$('.CommonGalleryMobile-image img');
        const w = $wrapper.width();
        const h = $wrapper.height();
        const aspect = $wrapper.attr('data-aspect') - 0;
        let ih;
        let iw;

        if (w / h > aspect) {
            iw = w;
            ih = w / aspect;
        } else {
            ih = h;
            iw = h * aspect;
        }

        $img.css({
            width: iw,
            height: ih
        });
    },


    onThumbClick: function (e) {
        const $thumb = $(e.currentTarget);
        this.showImage($thumb.attr('data-index') - 0, true);
    },


    showImage: function (index, hasAnimation) {
        this.trigger('index-changed', index);

        const $newImage = this.$('.CommonGalleryMobile-image').eq(index);
        const $thumbImage = this.$('.CommonGalleryMobile-thumb').eq(index);

        this.$('.CommonGalleryMobile-thumb').removeClass('active');
        $thumbImage.addClass('active');

        if (this.$prevImage) {
            this.$prevImage.css({
                'opacity': 0,
                'transition-delay': '400ms'
            });
        }

        $newImage.css({
            'opacity': 1,
            'z-index': ++this.zIndex,
            'transition-delay': ''
        });

        clearTimeout(this.showTimeout);

        if (!hasAnimation) {
            $newImage.css({
                'transition': 'none'
            }).outerHeight();

            $newImage.css({
                'transition': ''
            });
        }

        this.loadLazy(index);

        this.$prevImage = $newImage;
    },


    loadLazy: function (index) {
        const indexPrev = (index - 1 + this.photosCount) % this.photosCount;
        const indexNext = (index + 1) % this.photosCount;

        const lazyLoad = ($item) => {
            if (!$item.length) {
                return;
            }

            $item = $item.find('img');

            if ($item.data('loaded')) {
                return;
            }

            $item.data('loaded', true);
            $item.parent()
                .find('source, img')
                .each(function () {
                    var srcset = this.getAttribute('data-srcset');
                    var sizes = this.getAttribute('data-sizes');
                    var src = this.getAttribute('data-src');
                    srcset && this.setAttribute('srcset', srcset);
                    sizes && this.setAttribute('sizes', sizes);
                    src && this.setAttribute('src', src);
                });
        };

        const $photos = this.$('.CommonGalleryMobile-image');

        lazyLoad($photos.eq(indexPrev));
        lazyLoad($photos.eq(index));
        lazyLoad($photos.eq(indexNext));
    },




    destroy: function () {
        $(window).off('resize', this.onResizeLocal);

        return Base.prototype.destroy.call(this);
    }
});
