'use strict';

// var _ = require('underscore/underscore.js');
var Base = require('front/components/Base/Base');

require('./IndexFeedbackItem.less');


module.exports = Base.extend({

    events: {
        'click .IndexFeedbackItem-expand': 'onExpandClick'
    },

    initialize: function (options) {
        this.$el.onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });

        this.$expandWrapper = this.$('.IndexFeedbackItem-expand-wrapper');
        this.$textWrapper = this.$('.IndexFeedbackItem-message-body');

        this.$expandWrapper.toggle(this.$textWrapper.find('p').length > 1);

        Base.prototype.initialize.call(this, options);
    },


    onExpandClick: function () {
        const h1 = this.$textWrapper.outerHeight(true);
        this.$textWrapper.removeClass('collapsed');
        const h2 = this.$textWrapper.outerHeight(true);

        this.$textWrapper.css({
            'max-height': h1,
            'transition': 'none'
        });


        this.$textWrapper.outerHeight(true);

        this.$textWrapper.css({
            'max-height': h2,
            'transition': ''
        });

        setTimeout(() => {
            this.$textWrapper.css('max-height', 'none');
            app.state.forceResize = true;
            $(window).resize();
        }, 400);

        this.$expandWrapper.addClass('hidden');
    }

});
