'use strict';

var _ = require('underscore/underscore');
var BasePopup = require('front/components/BasePopup/BasePopup');

var template = require('./PhotosPopup.jinja');
require('./PhotosPopup.less');

module.exports = BasePopup.extend({

    template: template,

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER:  _.extend({

    }, BasePopup.prototype.VIEWS_TO_REGISTER),

    events: _.extend({
        'click .PhotosPopup-arrow-prev': 'onPrevClick',
        'click .PhotosPopup-arrow-next': 'onNextClick'
    }, BasePopup.prototype.baseEvents),


    apiUrl: function () {
        return window.UrlsAPI['api:photos-popup']('json');
    },

    renderData: function () {
        return this.data || {};
    },

    initialize: function (options) {
        this.options = options || {};

        _.bindAll(this, 'onResizeLocal', 'onKeyUp');

        this.index = (parseInt(options.params, 10) || 1) - 1;

        this.showLoader = true;

        BasePopup.prototype.initialize.call(this, options);
    },


    activate: function () {
        return BasePopup.prototype.activate.call(this).then(() => {
            this.zIndex = 0;

            this.photosCount =  this.$('.PhotosPopup-image').length;

            if (this.index >= this.photosCount) {
                this.index = 0;
            }

            this.showImage(this.index, false);

            app.vent.on('resize', this.onResizeLocal);

            $(window).on('keyup', this.onKeyUp);

            setTimeout(this.onResizeLocal, 34);
        });
    },


    deactivate: function (params) {
        app.vent.off('resize', this.onResizeLocal);

        $(window).off('keyup', this.onKeyUp);

        return BasePopup.prototype.deactivate.call(this, params);
    },


    onResizeLocal: function () {
        const $image = this.$('.PhotosPopup-image').eq(this.index);
        const $wrapper = $('.PhotosPopup-image-wrapper', $image);
        const $img = $('img', $wrapper);
        const w = $wrapper.width();
        const h = $wrapper.height();
        const aspect = $wrapper.attr('data-aspect') - 0;
        let ih;
        let iw;

        if (w / h > aspect) {
            iw = w;
            ih = w / aspect;
        } else {
            ih = h;
            iw = h * aspect;
        }

        $img.css({
            width: iw,
            height: ih
        });
    },


    showImage: function (index, hasAnimation) {
        Backbone.history.navigate(window.Urls['front:photos-popup'](index + 1), {trigger: false});

        const $newImage = this.$('.PhotosPopup-image').eq(index);

        if (this.$prevImage) {
            this.$prevImage.css({
                'opacity': 0,
                'transition-delay': '400ms'
            });
        }

        $newImage.css({
            'opacity': 1,
            'z-index': ++this.zIndex,
            'transition-delay': ''
        });

        clearTimeout(this.showTimeout);

        if (!hasAnimation) {
            $newImage.css({
                'transition': 'none'
            }).outerHeight();

            $newImage.css({
                'transition': ''
            });
        }

        this.loadLazy(this.index);

        this.$prevImage = $newImage;
    },


    getNextIndex: function (realIndex, dir) {
        const list = app.data.photosRandomMap;
        const index = list.indexOf(realIndex);

        return list[(index + dir + this.photosCount) % this.photosCount];
    },


    loadLazy: function (index) {
        const indexPrev = this.getNextIndex(this.index, -1);
        const indexNext = this.getNextIndex(this.index, +1);

        const lazyLoad = ($item) => {
            if (!$item.length) {
                return;
            }

            $item = $item.find('img');

            if ($item.data('loaded')) {
                return;
            }

            $item.data('loaded', true);
            $item.parent()
                .find('source, img')
                .each(function () {
                    var srcset = this.getAttribute('data-srcset');
                    var sizes = this.getAttribute('data-sizes');
                    var src = this.getAttribute('data-src');
                    srcset && this.setAttribute('srcset', srcset);
                    sizes && this.setAttribute('sizes', sizes);
                    src && this.setAttribute('src', src);
                });
        };

        const $photos = this.$('.PhotosPopup-image');

        lazyLoad($photos.eq(indexPrev));
        lazyLoad($photos.eq(index));
        lazyLoad($photos.eq(indexNext));
    },


    onKeyUp: function (e) {
        if (e.keyCode === 37) { //left
            this.onPrevClick();
        }

        if (e.keyCode === 39) { //right
            this.onNextClick();
        }
    },


    onPrevClick: function () {
        this.index = this.getNextIndex(this.index, -1);
        this.showImage(this.index, true);
    },

    onNextClick: function () {
        this.index = this.getNextIndex(this.index, 1);
        this.showImage(this.index, true);
    }

});
