'use strict';

var Base = require('front/components/Base/Base');

require('./IndexAuthors.less');

module.exports = Base.extend({

    events: {
    },

    initialize: function (options) {
        this.options = options || {};

        app.vent.on('loaded', () => {
            this.headerAnimator = new app.utils.HeaderAnimator(this.$('h2'));

            setTimeout(() => {
                this.$el.addClass('show-border');
                this.headerAnimator.fire();
                this.headerAnimator = null;
            }, 700);
        });

        this.$('.IndexAuthors-columns').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });

        Base.prototype.initialize.call(this, options);
    }

});
