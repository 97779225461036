var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/BasePopup/BasePopup.jinja"] = require( "front/components/BasePopup/BasePopup.jinja" );




var shim = require("/var/www/django/sencessence.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/DonatePopup/DonatePopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/DonatePopup/DonatePopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n";
env.getTemplate("front/components/BasePopup/BasePopup.jinja", true, "assets/app/front/pages/DonatePopup/DonatePopup.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
parentTemplate = t_5
for(var t_4 in parentTemplate.blocks) {
context.addBlock(t_4, parentTemplate.blocks[t_4]);
}
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popupMetaTitle"))(env, context, frame, runtime, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
output += t_7;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popupContent"))(env, context, frame, runtime, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
output += t_9;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popupMetaTitle(env, context, frame, runtime, cb) {
var lineno = 3;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"OG")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popupContent(env, context, frame, runtime, cb) {
var lineno = 5;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"DonatePopup\">\n        <div class=\"DonatePopup-wrapper\">\n            <div class=\"DonatePopup-header\">\n                <h1>";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"header")),"title")), env.opts.autoescape);
output += "</h1>\n                <div>\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"header")),"note")), env.opts.autoescape);
output += "\n                </div>\n                <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"header")),"image")),"w600"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"header")),"title"), env.opts.autoescape);
output += "\">\n            </div>\n\n            <div class=\"DonatePopup-error\">Это поле обязательно!</div>\n\n            <div class=\"DonatePopup-price\">\n                <div class=\"DonatePopup-left\">\n                    <div class=\"DonatePopup-input big\">\n                        <input type=\"text\" autocomplete=\"off\" maxlength=\"7\" name=\"amount\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"price")),"pricePlaceholder"), env.opts.autoescape);
output += "\" required />\n                        <div class=\"DonatePopup-price-input-currency\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"currency"), env.opts.autoescape);
output += "</div>\n                    </div>\n                    <div class=\"DonatePopup-price-presets\">\n                        ";
frame = frame.push();
var t_13 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"price")),"presets");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("preset", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\n                            <button class=\"DonatePopup-price-preset\" data-value=\"";
output += runtime.suppressValue(t_14, env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(t_14, env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"currency"), env.opts.autoescape);
output += "</button>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    </div>\n                    <div class=\"DonatePopup-form\">\n                        <div class=\"DonatePopup-input small\">\n                            <label>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"address")),"name"), env.opts.autoescape);
output += "</label>\n                            <input type=\"text\" name=\"name\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"address")),"fill"), env.opts.autoescape);
output += "\" />\n                        </div>\n                        <div class=\"DonatePopup-input small\">\n                            <label>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"address")),"email"), env.opts.autoescape);
output += "</label>\n                            <input type=\"email\" name=\"email\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"address")),"fillEmail"), env.opts.autoescape);
output += "\" />\n                        </div>\n                        <div class=\"DonatePopup-input small\">\n                            <label>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"address")),"comment"), env.opts.autoescape);
output += "</label>\n                            <textarea name=\"comment\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"address")),"fillComment"), env.opts.autoescape);
output += "\" rows=\"1\"></textarea>\n                        </div>\n                    </div>\n                    <div class=\"DonatePopup-agreed\">\n                        <div class=\"DonatePopup-agreed-checkbox\">\n                            <input type=\"checkbox\" name=\"agreed\" id=\"DonatePopup-agreed\" value=\"1\">\n                            <label for=\"DonatePopup-agreed\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"footer")),"checkbox")), env.opts.autoescape);
output += "</label>\n                        </div>\n                    </div>\n                    <div class=\"DonatePopup-agreed\">\n                        <div class=\"DonatePopup-agreed_personal-checkbox\">\n                            <input type=\"checkbox\" name=\"agreed_personal\" id=\"DonatePopup-agreed_personal\" value=\"1\">\n                            <label for=\"DonatePopup-agreed_personal\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"footer")),"checkboxPersonal")), env.opts.autoescape);
output += "</label>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"DonatePopup-footer\">\n                <div class=\"DonatePopup-left\">\n                    <img class=\"DonatePopup-footer-logos-desktop\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"footer")),"image")),"desktop"), env.opts.autoescape);
output += "\" alt=\"Tinkoff, Mastercard, Visa, Apple Pay, Google Pay\">\n                    <img class=\"DonatePopup-footer-logos-mobile\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"footer")),"image")),"mobile"), env.opts.autoescape);
output += "\" alt=\"Tinkoff, Mastercard, Visa, Apple Pay, Google Pay\">\n                </div>\n\n                <div class=\"DonatePopup-right\">\n                    <button class=\"DonatePopup-footer-pay\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"footer")),"pay"), env.opts.autoescape);
output += " <span data-currency=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"currency"), env.opts.autoescape);
output += "\"></span> <em>";
output += runtime.suppressValue((lineno = 65, colno = 161, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["order-arrow","0 0 56 33",runtime.makeKeywordArgs({"caller": (function (){var macro_t_15 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_16 = "";;
frame = frame.pop();
return new runtime.SafeString(t_16);
});
return macro_t_15;})()})])), env.opts.autoescape);
output += "</em></button>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"TinkoffPayForm\">\n            <form name=\"TinkoffPayForm\" onsubmit=\"pay(this); return false;\">\n                <input class=\"tinkoffPayRow\" type=\"hidden\" name=\"terminalkey\" value=\"1620816007128\">\n                <input class=\"tinkoffPayRow\" type=\"hidden\" name=\"frame\" value=\"false\">\n                <input class=\"tinkoffPayRow\" type=\"hidden\" name=\"language\" value=\"ru\">\n                <input class=\"tinkoffPayRow\" type=\"text\" name=\"amount\" placeholder=\"Сумма заказа\" value=\"\" required>\n                <input class=\"tinkoffPayRow\" type=\"text\" name=\"order\" placeholder=\"Номер заказа\" value=\"\">\n                <input class=\"tinkoffPayRow\" type=\"text\" name=\"description\" placeholder=\"Описание заказа\" value=\"Пожертвование проекту «Эссенция сенца»\">\n                <input class=\"tinkoffPayRow\" type=\"submit\" value=\"Оплатить\">\n            </form>\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_popupMetaTitle: b_popupMetaTitle,
b_popupContent: b_popupContent,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/DonatePopup/DonatePopup.jinja"] , dependencies)