'use strict';

var _ = require('underscore/underscore');
var BasePopup = require('front/components/BasePopup/BasePopup');

var template = require('./OrderPopup.jinja');
require('./OrderPopup.less');
require('jquery.numeric');

module.exports = BasePopup.extend({

    template: template,

    //Base creates an instances for each one of found dom nodes (by selector) when parent view is rendered itself
    VIEWS_TO_REGISTER: _.extend({

    }, BasePopup.prototype.VIEWS_TO_REGISTER),

    events: _.extend({
        'change .OrderPopup-delivery-radio input': 'onDeliveryUpdate',
        'input .OrderPopup-delivery-radio input': 'onDeliveryUpdate',
        'click .OrderPopup-footer-pay': 'onPayClick',
        'input input, textarea': 'onInputChange'
    }, BasePopup.prototype.baseEvents),


    apiUrl: function () {
        return window.UrlsAPI['api:order-popup']('json');
    },

    renderData: function () {
        return this.data || {};
    },

    initialize: function (options) {
        this.options = options || {};

        BasePopup.prototype.initialize.call(this, options);
    },


    activate: function () {
        return BasePopup.prototype.activate.call(this);
    },


    onResize: function () {},


    deactivate: function (params) {
        return BasePopup.prototype.deactivate.call(this, params);
    },


    restoreState: function () {},


    saveState: function () {}
});
