'use strict';

const _ = require('underscore');
var Base = require('front/components/Base/Base');

require('./IndexBook.less');

const THREE = require('three');
const TDSLoader = require('three/examples/jsm/loaders/TDSLoader').TDSLoader;
// const OrbitControls = require('three-orbitcontrols');
// const GUI = require('dat.gui').GUI;

module.exports = Base.extend({

    events: {
    },

    initialize: function (options) {
        this.options = options || {};

        this.settings = {
            'dx': 0,
            'dy': -1,
            'dz': 0,
            'rx': 2.286,
            'ry': 2.494,
            'rz': 0.072,
            'lx': 0,
            'ly': 0,
            'lz': 0
        };

        this.parallaxData = {
            cur: 0,
            velocity: 0,
            strength: 3
        };


        app.vent.on('loaded', () => {
            this.headerAnimator = new app.utils.HeaderAnimator(this.$('h2'));
        });

        _.bindAll(this, 'onResize', 'rotate', 'onMouseMove', 'init3d');


        this.$('.IndexBook-image').onCustom('screen-enter', () => {
            this.isInView = true;
            $(window).on('mousemove', this.onMouseMove);

            this.init3d();

            if (!app.settings.isDesktop) {
                this.parallaxData.rafId = window.requestAnimationFrame(this.rotate);
            } else {
                if (!this.bookShowAnimation) {
                    this.bookShowAnimation = true;


                    this.parallaxData.cur = -1;
                    this.parallaxData.tar = 0;
                    this.parallaxData.rafId = window.requestAnimationFrame(this.rotate);
                }
            }
        });

        this.$('.IndexBook-image').onCustom('screen-leave', () => {
            this.isInView = false;
            $(window).off('mousemove', this.onMouseMove);

            if (!app.settings.isDesktop) {
                cancelAnimationFrame(this.parallaxData.rafId);
            }
        });

        this.$('h2').onCustom('screen-enter', (e, data) => {
            this.headerAnimator.fire(app.settings.showDelay);
            this.headerAnimator = null;
            data.$el.offCustom('screen-enter');
        });


        this.$('.IndexBook-feature').onCustom('screen-enter', (e, data) => {
            data.$el.addClass('show');
            data.$el.offCustom('screen-enter');
        });

        this.$('.IndexBook-number').onCustom('screen-enter', (e, data) => {
            const $item = data.$el.find('.IndexBook-number-value');
            const $number = $item.closest('.IndexBook-number');

            if (!$number.hasClass('wide')) {
                app.utils.animateNumber(
                    $item,
                    $item.attr('data-start'),
                    $item.attr('data-end'),
                    app.settings.showDelay,
                    1050,
                    null,
                    'easeOutQuart',
                    $item.attr('data-separator'),
                    $item.attr('data-digits')
                );
            } else {
                $number.addClass('show');
            }

            data.$el.offCustom('screen-enter');
        });

        if (window.location.search === '?grey' || window.location.search === '?violet') {
            this.init3d();
            $('body').css('visibility', 'hidden');
            $('canvas').css('visibility', 'visible');

            if (window.location.search === '?violet') {
                $('html').css('background', '#795FC5');
            }

            app.vent.on('loaded', () => {
                const h = $('canvas').height();
                const t = $('canvas').offset().top;
                window.scrollTo(0, t - (app.sizes.windowHeight - h) / 2);
            });
        }

        app.vent.on('loaded', () => {
            setTimeout(() => {
                this.init3d();
            }, app.sizes.isPhoneLayout ? 3000 : 1000);
        });


        // const panel = new GUI({
        //     width: 450
        // });
        // const s = this.settings;

        // panel.add(s, 'dx', -30, 30, 0.01);
        // panel.add(s, 'dy', -30, 30, 0.01);
        // panel.add(s, 'dz', -30, 30, 0.01);
        // panel.add(s, 'rx', -3, 3, 0.001);
        // panel.add(s, 'ry', -3, 3, 0.001);
        // panel.add(s, 'rz', -3, 3, 0.001);

        // panel.add(s, 'lx', -60, 60, 0.01);
        // panel.add(s, 'ly', -60, 60, 0.01);
        // panel.add(s, 'lz', -60, 60, 0.01);

        Base.prototype.initialize.call(this, options);
    },


    init3d: function () {
        if (this.is3dInited) {
            return;
        }

        this.is3dInited = true;

        this.loadingManager = new THREE.LoadingManager();

        this.loadingManager.onLoad = () => {
            if (this.loaded) {
                return;
            }

            this.loaded = true;

            app.vent.on('resize', this.onResize);

            this.onResize();
        };

        const assetsFolder = this.$('.IndexBook-image').attr('data-assets-folder');
        this.createScene();
        this.loadTextures(assetsFolder);
        this.createMaterials();
        this.loadModel(assetsFolder);
    },


    loadTextures: function (folder) {
        const textureLoader = new THREE.TextureLoader(this.loadingManager);

        this.textures = {
            diffuse: textureLoader.load(folder + 'diff.jpg'),
            bump: textureLoader.load(folder + 'bump2.jpg')
        };

        this.textures.diffuse.magFilter = THREE.LinearFilter;
        this.textures.diffuse.minFilter = THREE.LinearMipmapLinearFilter;
        this.textures.diffuse.anisotropy = Math.min(16, this.renderer.capabilities.getMaxAnisotropy());

        this.textures.bump.magFilter = THREE.LinearFilter;
        this.textures.bump.minFilter = THREE.LinearMipmapLinearFilter;
        this.textures.bump.anisotropy = Math.min(16, this.renderer.capabilities.getMaxAnisotropy());
    },


    createMaterials: function () {
        this.materials = {};

        this.materials.book = new THREE.MeshPhongMaterial({
            bumpMap: this.textures.bump,
            bumpScale: 1,
            map: this.textures.diffuse,
            shininess: 1,
            color: new THREE.Color(0xffffff),
            specular: new THREE.Color(0xffffff),
            side: THREE.FrontSide
        });
    },


    loadModel: function (folder) {
        const modelLoader = new TDSLoader(this.loadingManager);

        modelLoader.load(folder + 'book.3ds', (object) => {
            this.objects = {
                book: object.children[0]
            };

            this.assignMaterials();

            this.objects.book.castShadow = true;
            this.objects.book.receiveShadow = true;

            object.position.set(this.settings.dx, this.settings.dy, this.settings.dz);
            object.rotation.set(this.settings.rx, this.settings.ry, this.settings.rz);

            this.scene.add(object);

            this.model = object;
        });
    },


    assignMaterials: function () {
        this.objects.book.material = this.materials.book;
    },


    createScene: function () {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true
        });
        this.renderer.physicallyBasedShading = true;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMapSoft = true;
        this.renderer.setPixelRatio(window.devicePixelRatio || 1); //set retina for uses devices
        this.$('.IndexBook-image').append(this.renderer.domElement);

        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera(24, 1, 0.1, 10000);

        // this.camera.position.set(92.84685222511764, 164.61233234155935, 123.46628641060994);
        // this.camera.rotation.set(-1.0563373583885844, 0.6405795145797789, 0.813280710265706);
        // this.camera.position.set(0.6128302910033416, -140.3588129978253, 2.9349655021801837);
        // this.camera.rotation.set(Math.PI / 2, 0, 0);
        // this.camera.lookAt(0, 0, 0);

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        // this.controls.enableZoom = true;
        // this.controls.enablePan = true;
        // this.controls.zoomSpeed = 0.2;

        // this.controls.addEventListener( 'change', () => {
        //     console.log(this.camera.position);
        //     console.log(this.camera.rotation);
        //     this.renderer.render(this.scene, this.camera);
        // } );

        this.ambientLight = new THREE.AmbientLight(0xdddddd, 0.87);
        this.scene.add(this.ambientLight);

        this.pointLight1 = new THREE.PointLight(0xaaaaaa, 0.26);
        this.pointLight1.position.set(0, 225, -200);
        this.scene.add(this.pointLight1);

        this.pointLight1.castShadow = true;
        this.pointLight1.shadow.mapSize.width = 1024;
        this.pointLight1.shadow.mapSize.height = 1024;
        this.pointLight1.shadow.radius = 4;
    },


    onMouseMove: function (e) {
        if (!app.settings.isDesktop) {
            return;
        }

        const hw = app.sizes.windowWidth / 2;

        if (e.pageX < hw) {
            this.parallaxData.tar = 0;
        } else {
            const dx = -2 * (e.pageX - hw - hw / 2) / hw;
            this.parallaxData.tar = dx * this.parallaxData.strength;
        }

        !this.parallaxData.rafId && (this.parallaxData.rafId = window.requestAnimationFrame(this.rotate));
    },


    rotate: function (time) {
        let equals = 0;
        const data = this.parallaxData;

        if (app.settings.isDesktop) {
            let dist = Math.abs(data.cur - data.tar);
            let scale = app.utils.smoothDamp(0, dist, data, 300, 1000, this.prevTime ? (time - this.prevTime) : 1 / 60);
            scale /= dist;

            if (isNaN(scale) || scale > 1) {
                scale = 1;
            }

            data.cur = data.cur + (data.tar - data.cur) * scale;

            if (Math.abs(data.cur - data.tar) <= 0.001) {
                data.cur = data.tar;
                equals++;
            }

            this.prevTime = time;
        } else {
            this.parallaxData.cur += 0.01;
        }

        if (equals < 1) {
            data.rafId = window.requestAnimationFrame(this.rotate);
        } else {
            data.rafId = undefined;
            this.prevTime = undefined;
        }


        this.pointLight1.position.x = Math.cos(data.cur + Math.PI / 2) * 170;
        this.pointLight1.position.z = Math.sin(data.cur + 3 * Math.PI / 2) * 170;

        this.camera.position.x = Math.cos(data.cur + Math.PI / 2) * 170;
        this.camera.position.z = Math.sin(data.cur + 3 * Math.PI / 2) * 170;

        this.camera.lookAt(this.settings.lx, this.settings.ly, this.settings.lz);

        this.renderer.render(this.scene, this.camera);
    },


    onResize: function () {
        if (!this.camera) {
            return;
        }

        const width = this.$('.IndexBook-image').width();
        const height = this.$('.IndexBook-image').height();

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
        this.renderer.render(this.scene, this.camera);
    }
});
