'use strict';

var Base = require('front/components/Base/Base');

require('./IndexFooter.less');

module.exports = Base.extend({

    events: {
    },

    initialize: function (options) {
        this.options = options || {};

        let reachBottom = true;

        this.$el.onCustom('screen-position', (e, data) => {
            let progress = (data.screenBottom + data.height) / data.height;
            progress = Math.max(Math.min(progress, 1), 0);

            if (progress > 0.2 && !reachBottom) {
                reachBottom = true;
                app.vent.trigger('reach-bottom', true);
            }

            if (progress <= 0.2 && reachBottom) {
                reachBottom = false;
                app.vent.trigger('reach-bottom', false);
            }

            app.vent.trigger('footer-progress', {progress, height: data.height});
        });

        // app.vent.on('loaded', () => {
        //     window.scrollTo(0, this.$el.offset().top);
        // });


        Base.prototype.initialize.call(this, options);
    }

});
