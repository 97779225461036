var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/sencessence.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/IndexFeedback/IndexFeedbackItem.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/IndexFeedback/IndexFeedbackItem.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data", "hasAnimation"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("hasAnimation", Object.prototype.hasOwnProperty.call(kwargs, "hasAnimation") ? kwargs["hasAnimation"] : runtime.contextOrFrameLookup(context, frame, "False"));var t_5 = "";t_5 += "\n    <div class=\"IndexFeedbackItem\" data-scroll=\"throttled\" data-scroll-offset=\"40\">\n        <div class=\"IndexFeedbackItem-left\">\n            <h3>";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title")), env.opts.autoescape);
t_5 += "</h3>\n            <p>";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"position")), env.opts.autoescape);
t_5 += "</p>\n        </div>\n        <div class=\"IndexFeedbackItem-right\">\n            <div class=\"IndexFeedbackItem-message-body collapsed\">\n                ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"text")), env.opts.autoescape);
t_5 += "\n            </div>\n\n            <div class=\"IndexFeedbackItem-expand-wrapper\">\n                <button class=\"IndexFeedbackItem-expand\">Дальше</button>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("IndexFeedbackItem");
context.setVariable("IndexFeedbackItem", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/IndexFeedback/IndexFeedbackItem.jinja"] , dependencies)