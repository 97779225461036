var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/IndexFeedback/IndexFeedbackItem.jinja"] = require( "front/components/IndexFeedback/IndexFeedbackItem.jinja" );




var shim = require("/var/www/django/sencessence.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/IndexFeedback/IndexFeedbackItemRuntime.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/IndexFeedback/IndexFeedbackItemRuntime.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/IndexFeedback/IndexFeedbackItem.jinja", false, "assets/app/front/components/IndexFeedback/IndexFeedbackItemRuntime.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "IndexFeedbackItem")) {
var t_7 = t_4.IndexFeedbackItem;
} else {
cb(new Error("cannot import 'IndexFeedbackItem'")); return;
}
context.setVariable("IndexFeedbackItem", t_7);
output += "\n\n";
output += runtime.suppressValue((lineno = 4, colno = 20, runtime.callWrap(t_7, "IndexFeedbackItem", context, [runtime.makeKeywordArgs({"data": runtime.contextOrFrameLookup(context, frame, "data"),"hasAnimation": runtime.contextOrFrameLookup(context, frame, "True")})])), env.opts.autoescape);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/IndexFeedback/IndexFeedbackItemRuntime.jinja"] , dependencies)