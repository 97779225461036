'use strict';

var Section = require('front/pages/Section/Section');

module.exports = Section.extend({

    el: '.NotFound',

    mainContentSelector: '.NotFound-content',

    events: {
    },


    initialize: function (options) {
        this.options = options || {};

        window.location.href = '/';

        Section.prototype.initialize.call(this, options);
    }
});
